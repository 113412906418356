.fav {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;

  @media only screen and (max-width: 1400px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .property_card_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: min-content;
    border-radius: 4px;
    margin-bottom: 30px;

    .property_card {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 12px 12px 0px 12px;
      width: 350px;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      flex-shrink: 0;

      border: 1px solid var(--LP-very-light, #eaedf3);
      background: #fff;
      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);

      .property_card-T {
        display: flex;
        justify-content: center;
        gap: 12px;

        .box {
          display: flex;
          width: 34px;
          height: 32px;
          padding: 6px 7px;
          flex-direction: column;
          align-items: center;
          gap: 4px;
          color: var(--LP-mid-grey-_lib, #6b6c6f);
          text-align: center;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 4px;
          border: 0px solid var(--LP-very-light, #eaedf3);
          background: rgba(125, 85, 199, 0.15);

          span {
            color: var(--LP-black--_lib, #3e3f42);
            text-align: center;
            font-family: Roboto;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            /* 128.571% */
          }
        }

        .text {
          display: flex;
          flex-direction: column;

          p {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }

        .photo_wrapper {
          max-width: 110px;
          display: flex;
          flex-direction: column;

          .tag {
            position: relative;
            z-index: 9;
            display: flex;
            padding: 4px;
            margin-bottom: -26px;
            /* display: inline-flex; */
            padding: 5px 10px;
            align-items: center;
            gap: 10px;
            border-radius: 4px 0px;
            background: #eaedf3;
            box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
            width: 120px;

            p {
              color: var(--LP-mid-grey-_lib, #6b6c6f);
              text-align: center;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          margin-bottom: 10px;
        }

        .text_wrapper {
          max-height: 100px;
          h3 {
            color: var(--LP-black, #3e3f42);
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 133.333% */
            margin-bottom: 6px;
            text-wrap: nowrap;
          }

          p {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            /* 133.333% */
          }
        }
      }

      .property_card-M {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 10px;

        .timer {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 20px;

          .left {
            .text {
              display: flex;
              flex-direction: column;
              gap: 4px;
            }

            p {
              color: var(--LP-mid-grey-_lib, #6b6c6f);
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              display: flex;
              align-items: center;
              gap: 2px;

              img {
                height: 16px;
                width: 16px;
              }
            }
          }

          .right {
            display: flex;
            justify-content: space-around;
            width: 191px;
            flex-direction: column;
            margin-top: -21px;
            text-align: left;

            h3 {
              color: var(--LP-black, #3e3f42);
              font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              margin-bottom: 6px;
              text-wrap: nowrap;
            }

            p {
              color: var(--LP-black, #3e3f42);
              font-family: Roboto;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              /* 26.4px */
            }

            span {
              color: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
              text-align: center;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 110%;
              /* 13.2px */
            }
          }
        }

        .boxes_wrapper {
          display: flex;
          flex-direction: row;
          gap: 0px;

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            outline: none;
            border: 1px solid #eaedf3;
            gap: 6px;
            width: 100%;
            height: 38px;
            background: linear-gradient(0deg, rgba(125, 85, 199, 0.15) 0%, rgba(255, 255, 255, 0) 107.78%);
            background-blend-mode: multiply;
            color: var(--LP-black, #3e3f42);
            text-align: center;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
        }

        .progressbar_wrapper {
          .header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;

            p {
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              /* 150% */
            }

            span {
              color: var(--Purple, #7d59c4);
              text-align: right;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              /* 128.571% */
            }
          }
        }
      }

      .property_card-B {
        border-top: 1px solid #f8f7fa;

        .buttons {
          padding: 10px 0px;
          display: flex;
          justify-content: space-between;

          .button {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;

            span {
              color: var(--LP-mid-gray, #6b6c6f);
              text-align: center;
              font-family: Roboto;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              /* 160% */
            }
          }
        }

        .stats {
          display: flex;
          justify-content: space-between;
          padding: 4px 0px;

          .date,
          .views,
          .active {
            display: flex;
            gap: 6px;
            color: var(--LP-gray, #9ea0a5);
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;

            /* 150% */
            span {
              color: var(--LP-gray, #9ea0a5);
              font-family: Roboto;
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
            }

            .dotgreen {
              height: 4px;
              width: 4px;
              border-radius: 200px;
            }
          }

          .active {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
