.Land_documents_total {
  display: flex;
  gap: 5px;
  span {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

  }
}



.Land_documents span {
  font-size: 14px;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordion-content.open {
  max-height: 500px;
}

.imageofdropdown{
    display: flex;
    gap: 30px;
}
.imageofdropdown{
    display: flex;
    gap: 15px;
    .imageofdropdown_Container{
        display: flex;
        gap: 9px;
        align-items: center;
    }
}

.dropdown-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.dropdown-content.open {
  max-height: 300px; /* Adjust based on the expected content height */
}
