.asset_history {
  p {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 900px) {
    width: fit-content;
    gap: 20px;
    display: flex;
    flex-direction: column;

  }

  .asset_history_box {
    padding-bottom: 20px;
    border-bottom: 1px solid #eaedf3;
    width: 500px;
    margin-bottom: 30px;

    .box_upper {
      display: flex;
      justify-content: space-between;
    }

    .box_lower {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: 15px;
      span {
        color: #9ea0a5;
        text-align: right;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }

    .property_card-T {
      display: flex;
      gap: 12px;

      .box {
        display: flex;
        width: 34px;
        height: 32px;
        padding: 6px 7px;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        color: var(--LP-mid-grey-_lib, #6b6c6f);
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 4px;
        border: 0px solid var(--LP-very-light, #eaedf3);
        background-color: #eaeaea;
        span {
          color: var(--LP-black--_lib, #3e3f42);
          text-align: center;
          font-family: Roboto;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          /* 128.571% */
        }
      }

      .text {
        display: flex;
        flex-direction: column;

        p {
          display: flex;
          align-items: center;
          gap: 4px;
        }
      }

      .photo_wrapper {
        max-width: 110px;
        display: flex;
        flex-direction: column;

        .tag {
          position: relative;
          z-index: 9;
          display: flex;
          padding: 4px;
          margin-bottom: -26px;
          /* display: inline-flex; */
          padding: 5px 10px;
          align-items: center;
          gap: 10px;
          border-radius: 4px 0px;
          background: #eaedf3;
          box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
          width: 120px;

          p {
            color: var(--LP-mid-grey-_lib, #6b6c6f);
            text-align: center;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        margin-bottom: 10px;
      }

      .text_wrapper {
        max-height: 100px;

        h3 {
          color: var(--LP-black, #3e3f42);
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          /* 133.333% */
          margin-bottom: 6px;
          text-wrap: nowrap;
        }

        p {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          /* 133.333% */
        }
        .amount_assect {
          display: flex;
          align-items: center;
          gap: 20px;

          .bookmarkimages {
            display: flex;
            gap: 20px;
          }
          .bookmarkimages > svg {
            /* Common styles for all SVGs */
            width: 16px; /* Adjust as needed */
            height: 16px; /* Adjust as needed */
            margin-bottom: 8px;
          }

          /* Specific styles for each SVG */
          .bookmarkimages > .BookmarkSvg {
            /* Styles for BookmarkSvg */
            fill: blue; /* Example style */
          }

          .bookmarkimages > .CompareSvg {
            /* Styles for CompareSvg */
            fill: green; /* Example style */
          }

          .bookmarkimages > .ShareSvg {
            /* Styles for ShareSvg */
            fill: red; /* Example style */
          }
        }
      }
    }
  }
}

.middle_assectdata {
  display: flex;
  gap: 30px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
}
