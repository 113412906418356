.Mui-checked {
  .MuiSwitch-track {
    background-color: #764fc2 !important;
  }
}

.AiSearch {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 5px;

  .AiSearch_container {
    padding: 20px 0px;
    z-index: 100;
    position: absolute;
    top: 100%;
    margin-top: 4px;
    border-radius: 8px;
    background: linear-gradient(180deg, rgb(56, 56, 56) -7%, rgb(255, 255, 255) 2%);
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.2);

    .AiSearch_container_left {
      border-right: 1px solid #c4c4c4;
    }
    .AiSearch_container_right {
      position: relative;
      .right {
        position: relative;
        left: -80px;
        scale: 1.2;
      }
    }
    .AiSearch_box {
      display: flex;
      .left {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 40px;
        h2 {
          color: #000;
          font-family: Roboto;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }
        p {
          color: var(--LP-mid-gray, #6b6c6f);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
        }
        .PrimaryButton {
          max-width: 150px;
          margin-top: 0px;
        }
      }
      .right {
        margin-top: 60px;
      }
    }
  }
}
.AssetDiscovery {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;

  .inputBoxMain input {
    // padding-left: 20px;
    padding-right: 40px;
  }
  &.Crowdfunding {
    .AssetDiscovery-T {
      background-image: url("../../assets/Image/assetDiscovery/BannerTwo.png");
    }
  }
  .AssetDiscovery-T {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/Image/assetDiscovery/BannerOne.png");
    margin: auto;
    padding: 25px;

    @media screen and (max-width: 1344px) {
      width: fit-content !important;
    }

    height: 500px;
    .white_buttons {
      display: flex;
      padding: 0px;
      align-items: center;
      height: 40px;
      padding: 0px 14px;
      justify-content: center;
      gap: 10px;
      background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
      box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
      span {
        color: #3e3f42;
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        font-weight: 500;
      }

      border-radius: 4px;
      border: 1px solid #d8dce6;
      background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
      box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
    }
  }
  .AssetDiscoverySearch {
    // background-color: #3e3f42;
    // width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    top: -40px;

    /* 9dp/Light */
    .AssetDiscoverySearch-T {
      display: flex;
      justify-content: space-between;
      .AssetDiscoverySearch-T-R {
        display: flex;
        gap: 10px;
        align-items: center;
      }
      .AssetDiscoverySearch-tabItem {
        width: 150px;
        height: 56px;
        margin-bottom: 20px;
        flex-shrink: 0;
        border-radius: 8px 8px 0px 0px;
        // background: green;
        display: flex;
        justify-content: center;
        padding-top: 14px;
        /* 6dp/Light */
        position: relative;
        top: 35px;
        z-index: 1;

        border-radius: 8px 8px 0px 0px;
        background: linear-gradient(0deg, rgba(234, 237, 243, 0.2) 0%, rgba(234, 237, 243, 0.2) 100%), #fff;

        /* 6dp/Light */
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        cursor: pointer;
        p {
          color: var(--LP-mid-gray, #6b6c6f);
          position: relative;
          top: 0px;
        }
        &.active {
          top: 25px;
          border-radius: 8px 8px 0px 0px;
          background: var(--ffffff-white, #fff);
          z-index: 3;
          background: #fff;

          /* 6dp/Light */
          box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0);
          p {
            top: 4px;
            color: var(--LP-black, #3e3f42);
          }
        }
      }
    }
    .AssetDiscoverySearch-B {
      display: flex;
      position: relative;
      z-index: 2;
      border-radius: 8px;
      background: var(--ffffff-white, #fff);
      width: 1380px;
      height: 116px;
      border-radius: 8px;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      box-sizing: border-box;
      box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.2);

      @media screen and (max-width: 1400px) {
        flex-direction: column;
        align-items: start;
        width: 100% !important;
        height: fit-content;
        padding: 30px 20px 20px 30px;
        gap: 20px;
      }

      .left {
        width: 1040px;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        @media screen and (max-width: 1500px) {
          width: fit-content !important;
          gap: 10px;
        }
        @media screen and (max-width: 1344px) {
          gap: 4px;
        }
        @media screen and (max-width: 1300px) {
          flex-wrap: wrap !important;
          justify-content: start !important;
          gap: 20px !important;
        }
      }

      .right {
        scale: 0.9;
        .top {
          text-align: start;
          p {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            span {
              color: #7d55c7 !important;
              font-weight: 500;
            }
          }
          margin-bottom: 10px;
        }
        .bottom {
          display: flex;
          align-items: center;
          .switch_wrapper {
            scale: 0.9;
          }
          .search_button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0px;
            width: 120px;
            margin-left: 0px;
            gap: 8px;
          }
          label {
            color: #6b6c6f;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            text-wrap: nowrap;
          }
        }
      }
      //   background-color: red;
      .assetTypeItem {
        .buttons_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          height: 100%;
          button {
            display: inline-flex;
            padding: 9px 11px;
            align-items: center;
            gap: 10px;
            border-radius: 4px;
            border: 1px solid #d8dce6;
            background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
            box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
          }
        }
        .assetTypeItem-T {
          height: 20px;
          display: flex;
          align-items: center;
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
          gap: 10px;
        }
        @media screen and (max-width: 880px) {
          .performance {
            margin-left: 20px;
          }
        }
        .assetTypeItem-B {
          // max-width: 170px;
          .inputBoxMain {
            margin-top: 10px;
            input {
              box-sizing: border-box;
            }
          }
          p {
            display: none;
          }
          .performance_inputs {
            gap: 15px;
          }
          @media screen and (max-width: 880px) {
            .performance_inputs {
              gap: 20px !important;
              margin-left: 20px !important;
            }
          }
        }
      }
    }
    .AssetDiscoveryFilters {
      z-index: 100;
      position: absolute;
      top: 100%;
      margin-top: 4px;
      border-radius: 8px;
      background: linear-gradient(180deg, rgb(56, 56, 56) -7%, rgb(255 255 255 / 100%) 2%);
      width: 100%;
      box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.2);

      .AssetDiscoveryFilters-T {
        padding-top: 30px;
        .react-tabs {
          padding: 0px 30px;

          .inputBoxMain {
            margin-top: 0px;
          }
          .react-tabs__tab-list {
            border-bottom: 1px solid #eaedf3;

            margin: 0 auto;
            display: flex;
            justify-content: start;
            flex-wrap: wrap;
            gap: 20px;

            li {
              display: flex;
              justify-content: center;
              color: var(--LP-mid-gray, #6b6c6f);
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 171.429% */
              text-transform: capitalize;
              display: flex;
              height: 36px;
              align-items: flex-start;
              min-width: 60px;
              cursor: pointer;
            }
            .active {
              color: #3e3f42 !important;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 171.429% */
              background: var(--ffffff-white, #fff);
              box-shadow: 0px -2px 0px 0px #7d55c7 inset;
            }
          }
          .react-tabs__tab-panel--selected {
            padding-right: 15%;
            padding-left: 10%;
            padding-bottom: 4.5%;
            padding-top: 3%;
            display: flex;
            border-bottom: 1px solid #eaedf3;
            &.innerTabination {
              padding-left: 0%;
              padding-top: 2%;
              padding-right: 0px;
            }
            .left {
              .react-tabs {
                display: flex;
                padding: 0px;
                .react-tabs__tab-list {
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: baseline;
                  width: 220px;

                  padding: 0px;
                  margin: 0px;
                  li {
                    padding: 5px 16px;
                    display: flex;
                    align-items: center;
                    justify-content: start;
                  }
                  .active {
                    box-shadow: 3px 0px 0px 0px #7d55c7 inset;
                  }
                  border-bottom: none;
                }
                .react-tabs__tab-panel--selected {
                  padding: 0px;
                  padding-top: 1%;
                  border-bottom: none;
                }
              }
              .right {
                width: 100%;
                display: flex;
                justify-content: space-between;
                gap: 105px;
                .column {
                  :first-child {
                    margin-top: 0px !important;
                  }
                  .row {
                    width: 286px;
                    margin-top: 20px;

                    p {
                      color: var(--LP-black, #3e3f42);
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                    }
                    span {
                      color: var(--LP-gray, #9ea0a5);
                      text-align: right;
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                    }
                    .subCat {
                      display: flex;
                      justify-content: space-between;
                      .subCat-L {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                      }
                    }
                    .option {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .MuiCheckbox-root {
                        padding: 5px;
                      }
                      .MuiFormControlLabel-root {
                        margin-left: 20px;
                      }
                      .MuiFormControlLabel-label {
                        color: var(--LP-black, #3e3f42);
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 157.143% */
                      }
                      .option-L {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                      }
                    }
                  }
                }
              }
            }
            .right {
              width: 100%;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              gap: 30px;
              @media screen and (max-width: 915px) {
                justify-content: center !important;
              }
              .column {
                .row {
                  width: 307px;

                  @media screen and (max-width: 1000px) {
                    width: 286px !important;
                  }

                  @media screen and (max-width: 915px) {
                    width: 500px !important;
                  }
                }
              }
            }
            .location {
              width: 100%;
            }
            .asset {
            }
          }
        }
      }

      .AssetDiscoveryFilters-B {
        display: flex;
        align-items: center;
        padding: 20px 30px;
        justify-content: space-between;
        .white_buttons {
          margin: 0px;
          height: 30px;
          border-radius: 4px;
          border: 1px solid #eaedf3;
          background: #fbfbfd;
        }
        .applyButton {
          .PrimaryButton {
            margin-top: 0px;
            padding: 8px 20px;
          }
        }
      }
    }
  }

  .AssetDiscovery-B {
    display: flex;
    width: 100%;
    // background-color: red;
    padding: 0 60px;
    box-sizing: border-box;
    margin-top: 42px;
    flex-direction: column;

    @media screen and (max-width: 800px) {
      padding: 0px 42px;
    }
    .AssetDiscovery-B-header {
      display: flex;
      align-items: center;
      gap: 8px;
      svg {
        // position: relative;
        // top: -2px;
        margin-top: -2px;
      }
      h3 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
      }
    }

    .AssetDiscovery-crowdfundingCon {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-items: flex-start;
      gap: 40px;
      justify-items: center;

      @media screen and (max-width: 1400px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        // margin-left: 85px;
        gap: 40px;
        align-items: flex-start;
        justify-items: center;
      }
      @media screen and (max-width: 1200px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
        justify-items: center;
        // margin-left: 85px;
        gap: 40px;
      }
      @media screen and (max-width: 800px) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        align-items: flex-start;
        justify-items: center;
        // margin-left: 85px;
        gap: 40px;
      }

      .floatCard_wrapper {
        position: absolute;
        top: 51%;
        left: 3%;
        display: flex;
        align-items: flex-start;
        gap: 5px;
        z-index: 10;
        button {
          all: unset;
          margin-top: 5px;
          position: relative;
          z-index: 20;
        }
      }
      .property_card_wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 335px;
        border-radius: 4px;
        margin-bottom: 30px;
        .property_card {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 12px 12px 0px 12px;
          width: 100%;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
          flex-shrink: 0;

          border: 1px solid var(--LP-very-light, #eaedf3);
          background: #fff;

          box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
          .property_card-T {
            // display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            .photo_wrapper {
              display: flex;
              flex-direction: column;
              margin-bottom: 0 !important;

              .tag {
                position: static !important;
                z-index: 1;
                display: flex;
                padding: 4px;
                margin-bottom: 0 !important;
                /* display: inline-flex; */
                padding: 5px 10px;
                align-items: center;
                gap: 10px;
                border-radius: 4px 0px;
                background: #eaedf3;
                box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
                justify-content: center;
                width: fit-content;
                p {
                  color: var(--LP-mid-grey-_lib, #6b6c6f);
                  text-align: center;
                  font-family: Roboto;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
              margin-bottom: 10px;
            }
            .text_wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              gap: 5px;
              margin-top: 10px;
              h3 {
                color: var(--LP-black, #3e3f42);
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 133.333% */
                margin-bottom: 6px;
              }
              p {
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 133.333% */
              }
            }
          }
          .property_card-M {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-bottom: 10px;

            .timer {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .left {
                p {
                  color: var(--LP-mid-grey-_lib, #6b6c6f);
                  font-family: Roboto;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px; /* 150% */
                }
              }
              .right {
                display: flex;
                justify-content: space-around;
                width: 220px;
                .time {
                  display: flex;
                  flex-direction: column;
                  p {
                    color: var(--purple-pantone-2665-c, #7d55c7);
                    text-align: center;
                    font-family: Roboto;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 110%; /* 26.4px */
                  }
                  span {
                    color: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
                    text-align: center;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 110%; /* 13.2px */
                  }
                }
              }
            }
            .boxes_wrapper {
              display: flex;
              flex-direction: column;
              gap: 10px;

              .box {
                display: flex;
                width: 100%;
                padding: 6px 7px;
                flex-direction: column;
                align-items: center;
                gap: 4px;
                color: var(--LP-mid-grey-_lib, #6b6c6f);
                text-align: center;
                font-family: Roboto;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-radius: 4px;
                border: 0px solid var(--LP-very-light, #eaedf3);
                background: rgba(125, 85, 199, 0.15);
                height: 100%;
                justify-content: space-between;
                span {
                  color: var(--LP-black--_lib, #3e3f42);
                  text-align: center;
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 18px; /* 128.571% */
                }
              }
              .boxes1 {
                display: flex;
                align-items: center;
                gap: 10px;
              }
              .boxes2 {
                display: flex;
                align-items: center;
                gap: 10px;
                text-wrap: nowrap;
                .box {
                  border-radius: 4px;
                  border: 1px solid var(--LP-very-light--_lib, #eaedf3);
                  background: rgba(234, 237, 243, 0.5);
                }
              }
              .boxes3 {
                display: flex;
                align-items: center;
                gap: 10px;
                text-wrap: nowrap;
                .box {
                  border-radius: 4px;
                  border: 1px solid var(--LP-very-light, #eaedf3);
                  background: var(--ffffff-white, #fff);
                  height: 60px;
                  justify-content: space-between;
                }
              }
            }
            .progressbar_wrapper {
              .header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 4px;
                p {
                  color: #9ea0a5;
                  font-family: Roboto;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px; /* 150% */
                }
                span {
                  color: var(--Purple, #7d59c4);
                  text-align: right;
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px; /* 128.571% */
                }
              }
            }
          }
          .property_card-B {
            border-top: 1px solid #f8f7fa;
            .buttons {
              padding: 10px 0px;
              display: flex;
              justify-content: space-between;

              .button {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 5px;
                span {
                  color: var(--LP-mid-gray, #6b6c6f);
                  text-align: center;
                  font-family: Roboto;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px; /* 160% */
                }
              }
            }
            .stats {
              display: flex;
              justify-content: space-between;
              padding: 4px 0px;

              .date,
              .views,
              .active {
                display: flex;
                gap: 6px;
                color: var(--LP-gray, #9ea0a5);
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 150% */
                span {
                  color: var(--LP-gray, #9ea0a5);
                  font-family: Roboto;
                  font-size: 11px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px;
                }
                .dotgreen {
                  height: 4px;
                  width: 4px;
                  border-radius: 200px;
                  background-color: #34aa44;
                }
              }
              .active {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }

      .property_card_mini_wrapper {
        display: flex;
        flex-direction: column;
        width: min-content;
        border-radius: 4px;
        margin-bottom: 30px;
        // border: 1px solid var(--LP-very-light, #eaedf3);
        background: #fff;
        box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
        height: min-content;
        .react-tabs {
          .react-tabs__tab-list {
            display: flex;
            width: 100%;
            justify-content: space-around;
            .react-tabs__tab {
              display: flex;
              width: 58px;
              padding: 10px 20px 9px 18px;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              gap: 8px;
              span {
                color: var(--LP-mid-gray, #6b6c6f);
                text-align: center;
                font-family: Roboto;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 160% */
                text-wrap: nowrap;
              }
              &.active {
                background: linear-gradient(0deg, rgba(186, 175, 207, 0.1) 0%, rgba(186, 175, 207, 0.1) 100%), #fff;
                box-shadow: 0px 2px 4px 0px rgba(125, 85, 199, 0.1) inset;
                svg {
                  path {
                    fill: #7d55c7;
                  }
                }
              }
            }
          }
          .react-tabs__tab-panel {
            .tab_panel_wrapper {
              position: relative;
              background: linear-gradient(0deg, rgba(186, 175, 207, 0.1) 0%, rgba(186, 175, 207, 0.1) 100%), #fff;
              padding: 15px 30px 0px 30px;
              height: 235px;
              overflow-y: scroll;
              .contact_container {
                .row {
                  display: flex;
                  gap: 15px;

                  margin: 10px 0px;
                  max-width: 280px;
                  p {
                    color: var(--LP-black, #3e3f42);
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                  }
                  span {
                    color: #6b6c6f;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                    text-decoration-line: underline;
                  }
                }
              }
              .bookmark_container {
                .header {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  margin: 15px 0px;
                  padding: 0px 10px;
                  p {
                    color: var(--LP-mid-gray, #6b6c6f);
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                  }
                }
                .labels_wrapper {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  &:last-child {
                    margin-bottom: 20px;
                  }
                  .button_wrapper {
                    display: flex;
                    gap: 10px;
                  }
                  .label {
                    display: flex;
                    padding: 0px 10px;
                    border-radius: 20px;
                    height: 38px;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    border: 1px solid #e2e5ed;

                    input {
                      all: unset;
                      margin-bottom: 3px;
                    }
                    .left {
                      display: flex;
                      gap: 5px;
                      align-items: center;
                      img {
                        width: 20px;
                        height: 24px;
                      }
                      p {
                        color: var(--ffffff-white, #fff);
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 157.143% */
                        margin-top: 2px;
                      }
                    }
                    .right {
                      span {
                        color: var(--ffffff-white, #fff);
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 157.143% */
                      }
                    }
                  }
                  .colour_picker {
                    display: flex;
                    justify-content: space-between;
                    .circle {
                      height: 24px;
                      width: 24px;
                      box-shadow: inset 0.1em 0.1em 0.2em 0 rgba(151, 151, 151, 0.382), inset -0.2em -0.2em 0.2em 0 rgba(0, 0, 0, 0.01);
                      &.active {
                        border: 4px solid #fff;
                        box-shadow: rgba(50, 50, 50, 0.3) 0px 0px 10px 0px;
                      }
                    }
                  }
                }
              }
              .notebook_wrapper {
                height: 100%;
                width: 290px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .messages_wrapper {
                  height: 100%;
                  padding-bottom: 30px;
                  overflow: scroll;
                  .message {
                    margin-bottom: 20px;
                    .header {
                      display: flex;
                      gap: 10px;
                      margin-bottom: 10px;
                      .left {
                      }
                      .right {
                        p {
                          color: #3e3f42;
                          font-family: Roboto;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: 16px; /* 157.143% */
                        }
                        span {
                          color: #9ea0a5;
                          font-family: Roboto;
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 16px; /* 150% */
                        }
                      }
                    }
                    .textbox {
                      padding: 8px 24px;
                      border-radius: 0px 10px 10px 10px;
                      background: linear-gradient(90deg, #6b5de7 0%, #915cf4 101.5%);
                      p {
                        color: #fff;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 157.143% */
                      }
                      width: 80%;
                    }
                  }
                }
                .form_wrapper {
                  display: flex;
                  position: absolute;
                  width: 350px;
                  left: 0px;
                  bottom: 0px;
                  width: 100%;
                  fill: rgba(255, 255, 255, 0);
                  border: 1px solid #eaedf3;

                  background: #ffffff;
                  form {
                    display: flex;
                    width: 100%;
                    justify-content: flex-start;

                    align-items: center;
                    padding: 14px 30px;
                    input {
                      all: unset;
                      padding: 0px !important;
                      margin: auto 0;
                      width: 280px;
                    }
                  }
                  button {
                    all: unset;
                    margin-top: 4px;
                  }
                }
              }
              .comparision_wrapper {
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding: 10px 0px;
                width: 290px;
                .top {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  button {
                    display: flex;
                    padding: 8px 11px;
                    align-items: center;
                    gap: 10px;
                    border-radius: 4px;
                    border: 1px solid #d8dce6;
                    background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);

                    box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;

                    p {
                      color: var(--purple-pantone-2665-c, #7d55c7);
                      text-align: center;
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                    }
                  }
                  p {
                    color: #3e3f42;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                  }
                }
                .mid {
                  .choice_list {
                    padding: 20px 0px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    border-top: 1px solid #eaedf3;
                    border-bottom: 1px solid #eaedf3;
                    .row {
                      display: flex;
                      gap: 10px;
                      align-items: center;
                      text-wrap: nowrap;
                      .removeButton {
                        margin-left: 20px;
                      }
                    }
                  }
                }
                .bottom {
                  button {
                    display: inline-flex;
                    padding: 8px 20px;
                    align-items: center;
                    gap: 12px;
                    border-radius: 4px;
                    border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
                    background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
                    background-blend-mode: multiply, normal;

                    box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
                    p {
                      color: #fff;
                      text-align: center;
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                    }
                  }
                }
              }
              .share_wrapper {
                .input_wrapper {
                  display: flex;
                  align-items: end;
                  width: 100%;
                  .inputBoxMain {
                    width: 100%;
                  }
                  .PrimaryButton {
                    margin: 0px;
                    height: 36px;
                    margin-bottom: 8px;
                    margin-left: -2px;
                    padding: 0px 10px;
                    display: flex;
                    align-items: center;
                  }
                }
                .tags {
                  display: flex;
                  flex-direction: column;
                  gap: 5px;
                  padding-bottom: 20px;
                  border-bottom: 1px solid #eaedf3;
                  .tag {
                    gap: 5px;

                    border: 1px solid #eaedf3;
                    border-radius: 10px;
                    background: #fff;
                    align-items: center;
                    width: max-content;
                    padding: 3px 10px;
                    display: flex !important;

                    p {
                      color: #3e3f42;
                      font-family: Roboto;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 18px; /* 150% */
                      text-wrap: nowrap;
                    }
                  }
                }
                .copy_card {
                  margin-bottom: 20px;
                  position: relative;
                  .circle {
                    left: 26px;
                    position: relative;
                    top: 22px;
                    top: 20px;
                    width: 48px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #a386d7;
                    svg {
                    }
                  }
                  .box {
                    border-radius: 4px;
                    border: 1px solid #e2e5ed;
                    background: #fff;
                    padding: 28px 30px;
                    .text_wrapper {
                      span {
                        color: #6b6c6f;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 157.143% */
                      }
                      p {
                        color: #3e3f42;
                        font-family: Roboto;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px; /* 150% */
                      }
                    }
                    .PrimaryButton {
                      width: 100px;
                      margin-top: 20px;
                      padding: 8px;
                    }
                  }
                }
              }
              .investor_feed {
                .header {
                  margin-top: 8px;
                  display: flex;
                  justify-content: space-between;
                  p {
                    color: var(--LP-mid-gray, #6b6c6f);
                    font-family: Roboto;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px; /* 112.5% */
                  }
                  span {
                    color: var(--LP-mid-gray, #6b6c6f);
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 13px;
                  }
                }
                .bottom_wrapper {
                  max-width: 290px;
                  margin-top: 20px;
                }
              }
            }
          }
        }
        .property_card_mini {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 12px 12px 0px 12px;
          width: 350px;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
          flex-shrink: 0;

          .property_card_mini-T {
            display: flex;
            justify-content: space-between;
            gap: 14px;
            .photo_wrapper {
              display: flex;
              flex-direction: column;
              margin-bottom: 0px !important;

              .tag {
                position: relative;
                z-index: 9;
                display: flex;
                padding: 4px;
                margin-bottom: -26px;
                /* display: inline-flex; */
                padding: 5px 10px;
                align-items: center;
                gap: 10px;
                border-radius: 4px 0px;
                background: #eaedf3;
                box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
                width: 120px;
                p {
                  color: var(--LP-mid-grey-_lib, #6b6c6f);
                  text-align: center;
                  font-family: Roboto;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }

              margin-bottom: 10px;
            }
            .text_wrapper {
              h3 {
                text-wrap: nowrap;
                color: var(--LP-black, #3e3f42);
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */
                display: flex;
              }
              p {
                margin-top: 5px;
                display: flex;
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 133.333% */
              }
              .price_wrapper {
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                h2 {
                  color: #3e3f42;
                  font-family: Roboto;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }
                span {
                  color: var(--LP-gray, #9ea0a5);
                  font-family: Roboto;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 100%; /* 12px */
                }
              }
            }
          }
          .property_card_mini-M {
            display: flex;
            flex-direction: row;
            gap: 10px;

            .boxes {
              display: flex;
              flex-direction: column;
              gap: 18px;
              .top {
                display: flex;
                gap: 8px;
                .box {
                  display: flex;
                  width: 100%;
                  padding: 6px 7px;
                  flex-direction: column;
                  align-items: center;
                  gap: 4px;
                  color: var(--LP-mid-grey-_lib, #6b6c6f);
                  text-align: center;
                  font-family: Roboto;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  border-radius: 4px;
                  border: 0px solid var(--LP-very-light, #eaedf3);
                  background: #eaeaea;
                  color: var(--LP-gray, #9ea0a5);
                  text-align: center;
                  font-family: Roboto;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  height: 50px;
                  text-wrap: nowrap;
                  span {
                    color: var(--LP-mid-gray, #6b6c6f);
                    text-align: center;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px; /* 128.571% */
                  }
                }
              }
              .bottom {
                display: flex;
                justify-content: space-between;
                div {
                  span {
                    color: var(--LP-mid-gray, #6b6c6f);
                    font-family: Roboto;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%; /* 10px */
                  }
                  color: var(--LP-gray, #9ea0a5);
                  font-family: Roboto;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 100%;
                }
              }
            }
            .stats {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 4px 0px;
              gap: 10px;
              .date,
              .views,
              .active {
                display: flex;
                gap: 6px;
                color: var(--LP-gray, #9ea0a5);
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 150% */
                span {
                  color: var(--LP-gray, #9ea0a5);
                  font-family: Roboto;
                  font-size: 11px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px;
                }
                .dotgreen {
                  height: 4px;
                  width: 4px;
                  border-radius: 200px;
                  background-color: #34aa44;
                }
              }
              .active {
                display: flex;
                align-items: center;
              }
            }
            .timer {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .left {
                p {
                  color: var(--LP-mid-grey-_lib, #6b6c6f);
                  font-family: Roboto;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px; /* 150% */
                }
              }
              .right {
                display: flex;
                justify-content: space-around;
                width: 220px;
                .time {
                  display: flex;
                  flex-direction: column;
                  p {
                    color: var(--purple-pantone-2665-c, #7d55c7);
                    text-align: center;
                    font-family: Roboto;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 110%; /* 26.4px */
                  }
                  span {
                    color: var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
                    text-align: center;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 110%; /* 13.2px */
                  }
                }
              }
            }
            .boxes_wrapper {
              display: flex;
              flex-direction: column;
              gap: 10px;

              .box {
                display: flex;
                width: 100%;
                padding: 6px 7px;
                flex-direction: column;
                align-items: center;
                gap: 4px;
                color: var(--LP-mid-grey-_lib, #6b6c6f);
                text-align: center;
                font-family: Roboto;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-radius: 4px;
                border: 0px solid var(--LP-very-light, #eaedf3);
                background: rgba(125, 85, 199, 0.15);
                span {
                  color: var(--LP-black--_lib, #3e3f42);
                  text-align: center;
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 18px; /* 128.571% */
                }
              }
              .boxes1 {
                display: grid !important;
                align-items: center;
                gap: 10px;
                grid-template-columns: repeat(4, 1fr) !important;
              }
              .boxes2 {
                display: grid !important;
                grid-template-columns: repeat(2, 1fr) !important;
                align-items: center;
                gap: 10px;
                text-wrap: nowrap !important;
                .box {
                  border-radius: 4px;
                  border: 1px solid var(--LP-very-light--_lib, #eaedf3);
                  background: rgba(234, 237, 243, 0.5);

                  &:last-child {
                    grid-column: span 2 !important;
                  }
                }
              }
              .boxes3 {
                display: flex;
                align-items: center;
                gap: 10px;
                text-wrap: nowrap;
                .box {
                  border-radius: 4px;
                  border: 1px solid var(--LP-very-light, #eaedf3);
                  background: var(--ffffff-white, #fff);
                  justify-content: space-between;
                }
              }
            }
            .progressbar_wrapper {
              .header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 4px;
                p {
                  color: #9ea0a5;
                  font-family: Roboto;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px; /* 150% */
                }
                span {
                  color: var(--Purple, #7d59c4);
                  text-align: right;
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px; /* 128.571% */
                }
              }
            }
          }
        }
        .property_card_mini-B {
          border-top: none;
          .buttons {
            padding: 10px 0px;
            display: flex;
            justify-content: space-between;

            .button {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 5px;
              span {
                color: var(--LP-mid-gray, #6b6c6f);
                text-align: center;
                font-family: Roboto;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 160% */
              }
            }
          }
          .stats {
            display: flex;
            justify-content: space-between;
            padding: 4px 0px;

            .date,
            .views,
            .active {
              display: flex;
              gap: 6px;
              color: var(--LP-gray, #9ea0a5);
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 150% */
              span {
                color: var(--LP-gray, #9ea0a5);
                font-family: Roboto;
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
              }
              .dotgreen {
                height: 4px;
                width: 4px;
                border-radius: 200px;
                background-color: #34aa44;
              }
            }
            .active {
              display: flex;
              align-items: center;
            }
          }
        }
      }

      .view_details_button {
        border-radius: 0px 0px 4px 4px;
        background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
        background-blend-mode: multiply, normal;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        p {
          color: var(--ffffff-white, #fff);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
        }
      }
    }
    .AssetDiscovery-propertiesCon {
      margin-top: 29px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      justify-content: space-between;
      width: 100%;
      @media screen and (max-width: 1300px) {
        gap: 60px !important;
      }

      .AssetDiscovery-propertiesITEM {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        transition: box-shadow 0.3s ease;
        padding: 8px;
        border-radius: 8px;
        // box-shadow: 0 0 5px rgba(207, 207, 207, 0.5);
        @media screen and (max-width: 1300px) {
          justify-content: center !important;
        }

        @media screen and (max-width: 1300px) {
          .AssetDiscovery-propertiesITEM-L {
            flex-direction: column;
          }
        }

        .AssetDiscovery-propertiesITEM-M {
          // background-color: #009639;
          cursor: pointer;

          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 5px;
          h1 {
            color: var(--purple-pantone-2665-c, #7d55c7);

            /* h4/Medium 20px */
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            cursor: pointer;
            text-wrap: nowrap;
          }
          h2 {
            margin-top: 6px;
            color: var(--LP-mid-gray, #6b6c6f);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          h3 {
          }
          .fl {
            display: flex;
            gap: 10px;
            margin-top: 12px;
            margin-bottom: 3px;
          }
          .onSaleCon {
            display: flex;
            align-items: center;
            gap: 4px;
            color: var(--LP-mid-gray, #6b6c6f);

            /* Text/Regular 12px */
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .propertiesDetailLine {
            display: flex;
            align-items: center;
            margin-top: 10px;
            gap: 10px;
            align-items: flex-start;
            .blockOne {
              display: flex;
              flex-direction: column;
              width: 100%;
              align-items: flex-start;
              h1 {
                color: var(--LP-black, #3e3f42);
                font-family: Roboto;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
              h2 {
                color: var(--LP-mid-gray, #6b6c6f);
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                margin-top: 0 !important;
              }
            }
            .blockTwo {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              background: var(--eaeaea-background-grey, #eaeaea);
              padding: 6px 5px;
              box-sizing: border-box;

              img {
                min-width: 18px;
                min-height: 18px;
              }

              h1 {
                color: var(--LP-mid-gray, #6b6c6f);
                text-align: center;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 128.571% */
              }
              h2 {
                color: var(--LP-gray, #9ea0a5);
                text-align: center;
                font-family: Roboto;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                width: max-content;
              }
            }
          }
          span {
            margin-top: 4px;

            display: flex;
            color: var(--LP-mid-gray, #6b6c6f);
            font-family: Roboto;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 10px */
            p {
              margin-left: 3px;
              color: var(--LP-gray, #9ea0a5);
              font-family: Roboto;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
            }
          }
          h3 {
            // display: flex;
            color: var(--LP-mid-gray, #6b6c6f);
            max-width: 337px;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 16.8px */
            a {
              background: var(--button-normal-blue, linear-gradient(0deg, #1665d8 0%, #1f6fe5 100%));
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%;
            }
          }
          .viewCounter {
            text-wrap: nowrap;
            display: flex;
            gap: 8px;
            color: var(--LP-gray, #9ea0a5);
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            align-items: center;
          }
        }
      }
    }
    .AssetDiscovery-propertiesITEM-R {
      display: flex;
      gap: 20px;
      @media screen and (max-width: 1300px) {
        flex-direction: column;
      }
      .AssetDiscovery-propertiesITEM-R-L {
        min-width: 350px;
        .react-tabs {
          .react-tabs__tab-list {
            display: flex;
            width: 100%;
            justify-content: space-around;
            .react-tabs__tab {
              display: flex;
              width: 58px;
              padding: 10px 20px 9px 18px;
              justify-content: center;
              align-items: center;
              &.active {
                background: linear-gradient(0deg, rgba(186, 175, 207, 0.1) 0%, rgba(186, 175, 207, 0.1) 100%), #fff;
                box-shadow: 0px 2px 4px 0px rgba(125, 85, 199, 0.1) inset;
                svg {
                  path {
                    fill: #7d55c7;
                  }
                }
              }
            }
          }
          .react-tabs__tab-panel {
            .tab_panel_wrapper {
              position: relative;
              background: linear-gradient(0deg, rgba(186, 175, 207, 0.1) 0%, rgba(186, 175, 207, 0.1) 100%), #fff;
              padding: 15px 30px 0px 30px;
              height: 235px;
              overflow-y: scroll;
              border-radius: 0px 0 8px 8px;
              .contact_container {
                .row {
                  display: flex;
                  gap: 15px;
                  background: none;
                  margin: 10px 0px;
                  max-width: 280px;

                  .leftSection {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    width: 100%;
                  }

                  p {
                    color: var(--LP-black, #3e3f42);
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                  }
                  span {
                    color: #6b6c6f;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                    text-decoration-line: underline;
                  }
                }
              }
              .bookmark_container {
                .header {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  margin: 15px 0px;
                  padding: 0px 10px;
                  p {
                    color: var(--LP-mid-gray, #6b6c6f);
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                  }
                }
                .labels_wrapper {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  &:last-child {
                    margin-bottom: 20px;
                  }
                  .button_wrapper {
                    display: flex;
                    gap: 10px;
                  }
                  .label {
                    display: flex;
                    padding: 0px 10px;
                    border-radius: 20px;
                    height: 38px;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    border: 1px solid #e2e5ed;

                    input {
                      all: unset;
                      margin-bottom: 3px;
                    }
                    .left {
                      display: flex;
                      gap: 5px;
                      align-items: center;
                      img {
                        width: 20px;
                        height: 24px;
                      }
                      p {
                        color: var(--ffffff-white, #fff);
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 157.143% */
                        margin-top: 2px;
                      }
                    }
                    .right {
                      span {
                        color: var(--ffffff-white, #fff);
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 157.143% */
                      }
                    }
                  }
                  .colour_picker {
                    display: flex;
                    justify-content: space-between;
                    .circle {
                      height: 24px;
                      width: 24px;
                      box-shadow: inset 0.1em 0.1em 0.2em 0 rgba(151, 151, 151, 0.382), inset -0.2em -0.2em 0.2em 0 rgba(0, 0, 0, 0.01);
                      &.active {
                        border: 4px solid #fff;
                        box-shadow: rgba(50, 50, 50, 0.3) 0px 0px 10px 0px;
                      }
                    }
                  }
                }
              }
              .notebook_wrapper {
                height: 100%;
                width: 290px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .messages_wrapper {
                  height: 100%;
                  padding-bottom: 30px;
                  overflow: scroll;
                  .message {
                    margin-bottom: 20px;
                    .header {
                      display: flex;
                      gap: 10px;
                      margin-bottom: 10px;
                      .left {
                      }
                      .right {
                        p {
                          color: #3e3f42;
                          font-family: Roboto;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: 16px; /* 157.143% */
                        }
                        span {
                          color: #9ea0a5;
                          font-family: Roboto;
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 16px; /* 150% */
                        }
                      }
                    }
                    .textbox {
                      padding: 8px 24px;
                      border-radius: 0px 10px 10px 10px;
                      background: linear-gradient(90deg, #6b5de7 0%, #915cf4 101.5%);
                      p {
                        color: #fff;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 157.143% */
                      }
                      width: 80%;
                    }
                  }
                }
                .form_wrapper {
                  display: flex;
                  position: absolute;
                  width: 350px;
                  left: 0px;
                  bottom: 0px;
                  width: 100%;
                  fill: rgba(255, 255, 255, 0);
                  border: 1px solid #eaedf3;

                  background: #ffffff;
                  form {
                    display: flex;
                    width: 100%;
                    justify-content: flex-start;

                    align-items: center;
                    padding: 14px 30px;
                    input {
                      all: unset;
                      padding: 0px !important;
                      margin: auto 0;
                      width: 280px;
                    }
                  }
                  button {
                    all: unset;
                    margin-top: 4px;
                  }
                }
              }
              .comparision_wrapper {
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding: 10px 0px;
                width: 290px;
                .top {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  button {
                    display: flex;
                    padding: 8px 11px;
                    align-items: center;
                    gap: 10px;
                    border-radius: 4px;
                    border: 1px solid #d8dce6;
                    background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);

                    box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;

                    p {
                      color: var(--purple-pantone-2665-c, #7d55c7);
                      text-align: center;
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                    }
                  }
                  p {
                    color: #3e3f42;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 157.143% */
                  }
                }
                .mid {
                  .choice_list {
                    padding: 20px 0px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    border-top: 1px solid #eaedf3;
                    border-bottom: 1px solid #eaedf3;
                    .row {
                      display: flex;
                      gap: 10px;
                      align-items: center;
                      text-wrap: nowrap;

                      .removeButton {
                        margin-left: 20px;
                      }
                    }
                  }
                }
                .bottom {
                  button {
                    display: inline-flex;
                    padding: 8px 20px;
                    align-items: center;
                    gap: 12px;
                    border-radius: 4px;
                    border: 1px solid var(--purple-pantone-2665-c-71, rgba(125, 85, 199, 0.71));
                    background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
                    background-blend-mode: multiply, normal;

                    box-shadow: 0px 1px 1px 0px rgba(19, 31, 21, 0.1), 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset;
                    p {
                      color: #fff;
                      text-align: center;
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                    }
                  }
                }
              }
              .share_wrapper {
                .input_wrapper {
                  display: flex;
                  align-items: end;
                  width: 100%;
                  .inputBoxMain {
                    width: 100%;
                  }
                  .PrimaryButton {
                    margin: 0px;
                    height: 36px;
                    margin-bottom: 8px;
                    margin-left: -2px;
                    padding: 0px 10px;
                    display: flex;
                    align-items: center;
                  }
                }
                .tags {
                  display: flex;
                  flex-direction: column;
                  gap: 5px;
                  padding-bottom: 20px;
                  border-bottom: 1px solid #eaedf3;
                  .tag {
                    gap: 5px;

                    border: 1px solid #eaedf3;
                    border-radius: 10px;
                    background: #fff;
                    align-items: center;
                    width: max-content;
                    padding: 3px 10px;
                    display: flex !important;
                    p {
                      color: #3e3f42;
                      font-family: Roboto;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 18px; /* 150% */
                      text-wrap: nowrap;
                    }
                  }
                }
                .copy_card {
                  margin-bottom: 20px;
                  position: relative;
                  .circle {
                    left: 26px;
                    position: relative;
                    top: 22px;
                    top: 20px;
                    width: 48px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #a386d7;
                    svg {
                    }
                  }
                  .box {
                    border-radius: 4px;
                    border: 1px solid #e2e5ed;
                    background: #fff;
                    padding: 28px 30px;
                    .text_wrapper {
                      span {
                        color: #6b6c6f;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 157.143% */
                      }
                      p {
                        color: #3e3f42;
                        font-family: Roboto;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px; /* 150% */
                      }
                    }
                    .PrimaryButton {
                      width: 100px;
                      margin-top: 20px;
                      padding: 8px;
                    }
                  }
                }
              }
              .investor_feed {
                .header {
                  margin-top: 8px;
                  display: flex;
                  justify-content: space-between;
                  p {
                    color: var(--LP-mid-gray, #6b6c6f);
                    font-family: Roboto;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px; /* 112.5% */
                  }
                  span {
                    color: var(--LP-mid-gray, #6b6c6f);
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 13px;
                  }
                }
                .bottom_wrapper {
                  max-width: 290px;
                  margin-top: 20px;
                }
              }
            }
          }
        }
        .PropertiesDetailTabination {
          width: 100%;
          display: flex;
          justify-content: space-around;
        }
      }
      display: flex;
      .PropertiesDetailTabination {
        display: flex;
      }
    }
  }
}

.sellChancesCon {
  display: flex;
  //   padding: 3px 10px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  width: 100px;
  height: 24px;

  border-radius: 4px;
  border: 1px solid var(--56-c-568-success-positive-green, #56c568);
  background: var(--light-grad, linear-gradient(0deg, #f6f7f9 0%, #fff 100%));
  color: var(--green-pantone-355-c, #009639);

  /* All Caps/Medium 10px */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.sponsoredBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #eaedf3;
  background: var(--ffc-400-warning-idle-yellow, #ffc400);
  color: #3e3f42; /* All Caps/Medium 10px */
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.map_image {
  // max-width: fit-content !important;

  @media screen and (max-width: 1300px) {
    width: 100% !important;
    height: 286px;
  }
}
.bulding_img {
  width: 100%;
  min-width: 300px;
  max-width: 300px;
  height: 270px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}

.searchParamInput {
  background: rgba(177, 219, 255, 0.2);
  box-shadow: 4px 0px 0px 0px #56ccf2 inset;
  margin-top: 20px;
  border-radius: 6px;
  .container {
    align-items: flex-start;
    padding: 20px;
    p {
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      color: #6b6c6f;
    }
  }
}

.allBlocks {
  display: flex;
  align-items: center;
  gap: 5px;
}
.crownfunding_slider {
  button {
    display: none;
  }
  .thumb-caption {
    display: none;
  }
  .f-thumbs {
    height: 35px !important;
    margin: 0px !important;
  }
}

.AssetDiscovery {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 30px;
}

.AssetDiscovery-propertiesITEM:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

//

.btn_options {
  display: flex;

  button {
    width: 100%;
    border-radius: 0px 0px 4px 4px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-image: linear-gradient(to top, #f7f8fa, #fefefe);
    border: 1px solid #e2e5ed;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
  }
  a {
    width: 100%;
  }
}
.photo_card {
  width: 100% !important;
  max-height: 144px !important;
}

.range_selector_wrapper {
  position: relative;
  p {
    margin-top: 10px;
    display: block !important;
    width: 100%;
    min-width: 150px;
    height: 38px;
    border: 1px solid var(--LP-border, #e5e5e5);
    border-radius: 4px;
    padding: 8px 16px;
    outline: none;
    color: var(--LP-black, #3e3f42);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    span {
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .range_selector_box {
    position: absolute;
    z-index: 100;
    margin-top: 4px;
    border-radius: 8px;
    background: linear-gradient(180deg, rgb(56, 56, 56) -7%, rgb(255, 255, 255) 2%);
    width: 100%;
    box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.2);
    padding: 20px;

    .slider_box {
      margin-bottom: 8px;

      .MuiSlider-root {
        .MuiSlider-rail {
          background: #7d55c78a;
        }
        .MuiSlider-track {
          background: #7c4ed1c2;
          border: 1px solid #7c4ed1c2;
        }
      }

      .MuiSlider-thumb {
        background: linear-gradient(0deg, rgba(125, 85, 199, 0.3) 0%, rgba(255, 255, 255, 0) 107.78%), #7d55c7;
      }
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      border-radius: 4px;
      border: 1px solid #d8dce6;
      background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
      box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
      font-family: "Roboto";
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      width: 70px;
      margin: 0 auto;
    }
  }
}

.property_card-M {
  .boxes_wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .box {
      display: flex;
      width: 100%;
      padding: 6px 7px;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      color: var(--LP-mid-grey-_lib, #6b6c6f);
      text-align: center;
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 4px;
      border: 0px solid var(--LP-very-light, #eaedf3);
      background: rgba(125, 85, 199, 0.15);
      span {
        color: var(--LP-black--_lib, #3e3f42);
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 128.571% */
      }
    }
    .boxes1 {
      display: grid !important;
      align-items: center;
      gap: 10px;
      grid-template-columns: repeat(4, 1fr) !important;
    }
    .boxes2 {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr) !important;
      align-items: center;
      gap: 10px;
      text-wrap: nowrap !important;
      .box {
        border-radius: 4px;
        border: 1px solid var(--LP-very-light--_lib, #eaedf3);
        background: rgba(234, 237, 243, 0.5);

        &:last-child {
          grid-column: span 2 !important;
        }
      }
    }
    .boxes3 {
      display: flex;
      align-items: center;
      gap: 10px;
      text-wrap: nowrap;
      .box {
        border-radius: 4px;
        border: 1px solid var(--LP-very-light, #eaedf3);
        background: var(--ffffff-white, #fff);
        justify-content: space-between;
      }
    }
  }
}
