.asset-dashboard-main {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: Roboto;
  }
  .asset-dashbord-navigator {
    height: 40px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 20px;
    column-gap: 20px;
    border-bottom: 1px solid #eaedf3;
    img {
      width: 24px;
      height: 24px;
    }
    h4 {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #6b6c6f;
    }
  }

  .asset-container {
    display: grid;
    grid-template-columns: 16% 1fr;
    height: 100vh;

    .side-bar {
      border-right: 1px solid #eaedf3;
      padding: 15px 0px;
      .menu-header {
        font-weight: 500;
        font-size: 12px;
        color: #9ea0a5;
        padding-left: 20px;
      }
      .menu-items {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        // padding-left: 20px;

        a {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 500;
          text-decoration: none;
          color: #3e3f42;
          display: flex;
          align-items: center;
          column-gap: 10px;
          padding: 20px 20px;

          img {
            width: 20px;
            height: 20px;
          }

          &.active {
            border-left: 4px solid #7d55c7;
            transition: all 0.3;
            background-color: rgba(126, 87, 199, 0.05);
          }
        }
      }
    }
    .main {
    }
  }
}

.dashboard-container {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: Roboto;
  }

  // padding: 20px;

  .dashboard-title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #3e3f42;
    padding: 20px;
    border-bottom: 1px solid #eaedf3;
  }
  .summary-title {
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    color: #3e3f42;
    padding: 20px;
  }
  .summary-cards-container {
    display: grid;
    grid-template-columns: 23.75% 23.75% 23.75% 23.75%;
    gap: 20px;
    // justify-content: center;
    padding-left: 20px;
    // padding-right: 20px;

    .card {
      padding: 20px;
      border: 1px solid #eaedf3;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .card-heading {
        display: flex;
        column-gap: 20px;
        align-items: center;
        h4 {
          color: #3e3f42;
          font-weight: 500;
          font-size: 16px;
        }
        img {
          height: 24px;
          width: 24px;
        }
      }
      .card-stats {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 {
          color: #3e3f42;
          font-size: 30px;
          font-weight: 400;
          line-height: 44px;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }
      .progress-bar {
        p {
          color: #9ea0a5;
          font-size: 12px;
          font-weight: 400px;
          margin-top: 10px;
          line-height: 18px;
        }
      }
      .card-bargraph {
        img {
          height: 29px;
          width: 100%;
        }
      }
    }
  }
  .property-breakdown-title {
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    color: #3e3f42;
    padding: 20px;
  }
  .property-breakdown-container {
    padding-left: 20px;
    display: grid;
    /* gap: 45px; */
    grid-template-columns: repeat(6, 1fr);
    .breakdown-card {
      padding: 30px 20px;
      border: 1px solid #eaedf3;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .card-heading {
        display: flex;
        column-gap: 20px;
        align-items: center;
        h4 {
          color: #9ea0a5;
          font-weight: 500;
          font-size: 12px;
          text-transform: uppercase;
        }
        img {
          height: 16px;
          width: 16px;
        }
      }
      .card-stats {
        h1 {
          color: #3e3f42;
          font-size: 30px;
          font-weight: 400;
          line-height: 44px;
        }
      }
    }
  }
  .property-map-listing {
    padding: 20px;
    display: grid;
    grid-template-columns: 67% 1fr;
    gap: 20px;

    img {
      border-radius: 5px;
    }
    .similar-property-container {
      border: 1px solid #eaedf3;
      border-radius: 5px;
      padding: 10px;
      overflow-x: hidden;
      position: relative;

      .similar-property-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eaedf3;
        padding: 10px 20px;
        h4 {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #9ea0a5;
          text-transform: uppercase;
        }
        a {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #9ea0a5;
        }
      }
      .leftarrow {
        position: absolute;
        top: 50%;
        left: 0;
        cursor: pointer;
      }
      .rightarrow {
        position: absolute;
        top: 50%;
        cursor: pointer;
        right: 0;
      }
      .similar-property-slider {
        max-width: 329px;
        overflow-x: hidden;
        margin: auto;
        .slides_container {
          gap: 40px;
          flex-shrink: 0;
          width: 100%;
          display: flex;
          padding: 20px;
          .similar_card {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 300px;
            p {
              color: #6b6c6f;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%; /* 19.6px */
            }
            .button_wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 20px;
              gap: 20px;
              .PrimaryButton {
                width: 150px;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }

  .building-container {
    margin: 20px;
    border: 1px solid #eaedf3;
    border-radius: 5px;
    .building-title {
      font-size: 18px;
      line-height: 30px;
      font-weight: 500;
      color: #3e3f42;
      padding: 20px;
      border-bottom: 1px solid #eaedf3;
    }
    .building-stats-container {
      display: grid;
      grid-template-columns: 1.25fr 1.25fr 1.25fr 1.25fr;
      border-bottom: 1px solid #eaedf3;

      .stats-grid {
        padding: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        .stats-grid-title {
          font-weight: 500;
          color: #9ea0a5;
          font-size: 12px;
          line-height: 18px;
          text-transform: uppercase;
        }
        .stats-grid-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 10px;

          h1 {
            font-weight: 400;
            font-size: 30px;
            line-height: 44px;
            color: #3e3f42;
          }
          p {
            font-weight: 400;
            font-size: 12px;
            color: #ffffff;
            padding: 5px;
            border-radius: 2px;
          }
        }
      }
      .stats-grid:not(:first-child) {
        border-left: 1px solid #eaedf3;
      }
    }
  }
  .renters-invoices-container {
    padding: 20px;
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    gap: 40px;

    .renters {
      border: 1px solid #eaedf3;
      display: flex;
      flex-direction: column;
      height: fit-content;
      .renters-heading {
        color: #3e3f42;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        border-bottom: 1px solid #eaedf3;
        padding: 20px;
      }

      .renter-entity {
        padding: 15px 20px;
        border-bottom: 1px solid #eaedf3;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .entity-title {
          display: flex;
          column-gap: 10px;
          align-items: center;

          h4 {
            height: 38px;
            width: 38px;
            font-size: 16px;
            font-weight: 500;
            border-radius: 100%;
            color: #ffffff;
            padding: 9px 14px;
          }
          h6 {
            color: #3e3f42;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
          }
        }
        p {
          color: #6b6c6f;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .view-all-btn {
        padding: 15px 20px;
        text-align: center;
        color: #9ea0a5;
        font-size: 12px;
        font-weight: 500;
        margin: auto;
      }
    }
    .invoices {
      border: 1px solid #eaedf3;
      display: flex;
      flex-direction: column;
      height: fit-content;
      .invoices-heading {
        color: #3e3f42;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        border-bottom: 1px solid #eaedf3;
        padding: 20px;
      }
      .invoice-entity {
        width: 100%;
        padding: 23px 20px;
        border-bottom: 1px solid #eaedf3;
        display: grid;
        grid-template-columns: 45% 20% 20% 13% 1fr;
        align-items: center;

        .invoice-name {
          color: #3e3f42;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
        }
        .invoice-date {
          color: #6b6c6f;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
        }
        .invoice-hex {
          color: #9ea0a5;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
        }
        .invoice-amount {
          color: #3e3f42;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
        }
      }
      .view-all-btn {
        padding: 15px 20px;
        text-align: center;
        color: #9ea0a5;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}

.budgeting-container {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: Roboto;
  }

  padding-top: 20px;
  .budgeting-header {
    border-bottom: 1px solid #eaedf3;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    .budget-date {
      display: flex;
      align-items: center;
      span {
        color: var(--LP-gray, #9ea0a5);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 214.286% */
      }
      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 187.5% */
      }
      img {
        padding-left: 12px;
      }
    }
    .budgeting-title {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      color: #3e3f42;
      padding: 20px;
    }

    .budgeting-tab-container {
      display: flex;
      // column-gap: 20px;
      .budgeting-tab {
        cursor: pointer;
        display: flex;
        column-gap: 10px;
        align-items: center;
        padding: 20px;

        img {
          width: 20px;
          height: 20px;
          fill: #9ea0a5;
        }
        h4 {
          // display: flex;
          color: var(--LP-mid-gray, #6b6c6f);
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
          justify-content: center;
          align-items: center;
          gap: 30px;
        }
        &.active {
          color: var(--purple-pantone-2665-c, #7d55c7);
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
          text-transform: uppercase;
          img {
            fill: #7d55c7;
          }
          h4 {
            color: #7d55c7;
          }
        }
      }
      .budgeting-tab:not(:last-child) {
        border-right: 1px solid #eaedf3;
      }
    }
  }
  .budgeting-content {
    .overview-container {
      padding: 20px;
      margin-bottom: 10%;
      .overview-header {
        font-family: Roboto;
        color: #3e3f42;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
      }

      .overview-tabs {
        margin-top: 14px;
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        border-bottom: 1px solid #eaedf3;

        a {
          font-family: Roboto;
          color: #3e3f42;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
          font-weight: 500px;

          font-size: 12px;
          padding-bottom: 14px;
          color: #3e3f42;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
          &.active {
            border-bottom: 2px solid #7d55c7;
            transition: all 0.3;
          }
        }
      }
      .overview-tab-content {
        padding: 30px 0px;

        .financial-kpi-cards {
          display: grid;
          grid-template-columns: repeat(5,1fr);
          grid-gap: 20px;
        }
        .unitTurnOver {
          display: flex;
          flex-direction: column;
          gap: 40px;
          h2 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 155.556% */
          }
          .unitTurnOver-T {
            .unitTurnOver_table {
              border: 1px solid #eaedf3;
              border-radius: 4px;
              .header_bar {
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .right {
                  .search_bar {
                    display: flex;
                    align-items: center;
                    position: relative;
                    z-index: 1;
                    .inputBoxMain {
                      margin: 0px;
                    }
                    svg {
                      position: absolute;
                      z-index: 2;
                      right: 10px;
                      top: 15px;
                    }
                  }
                }
              }
              .container {
                table {
                  width: 100%;
                  .thead {
                  }
                  th {
                    text-align: left;
                    color: #9ea0a5;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px; /* 150% */
                    text-transform: uppercase;
                    padding: 15px 20px;
                    border-top: 1px solid #eaedf3;
                  }
                  tbody {
                    tr {
                      td {
                        text-wrap: nowrap;
                        border-top: 1px solid #eaedf3;
                        padding: 24px 20px;
                        color: #3e3f42;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 157.143% */
                        .unittype_wrapper {
                          display: flex;
                          align-items: center;
                          gap: 20px;
                          .type {
                            display: flex;
                            gap: 10px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .unitTurnOver-B {
            display: flex;
            flex-direction: column;
            gap: 40px;
            .dropDownOuterCon {
              margin: 0px;
              p {
                display: none;
              }
            }
            .unitTurnOver_bars {
              border: 1px solid #eaedf3;
              border-radius: 4px;
              // display: flex;
              .header_bar {
                padding: 20px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .right {
                  .search_bar {
                    width: 150px;
                    display: block;
                    align-items: center;
                    position: relative;
                    z-index: 1;
                    .inputBoxMain {
                      margin: 0px;
                    }
                    svg {
                      position: absolute;
                      z-index: 2;
                      right: 10px;
                      top: 15px;
                    }
                  }
                }
              }
              .container {
                .rows {
                  padding: 40px 20px;
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  gap: 20px;
                  .row {
                    display: flex;
                    gap: 30px;
                    width: 100%;
                    p {
                      min-width: 175px;
                      color: #3e3f42;
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                    }
                    .p {
                      color: #9ea0a5;
                      text-align: right;
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                    }
                  }
                }
              }
            }
            .buttons_wrapper {
              display: flex;
              gap: 20px;
            }
          }
        }
        .budgetComparision {
          display: flex;
          flex-direction: column;
          gap: 40px;
          h2 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 155.556% */
          }
          .budgetComparision-T {
            .budgetComparision_table {
              border: 1px solid #eaedf3;
              border-radius: 4px;
              .header_bar {
                // padding: 20px;
                // display: flex;
                // align-items: center;
                // justify-content: space-between;
                .top {
                  padding: 20px 20px;
                  display: flex;
                  align-items: flex-end;
                  width: 100%;
                  justify-content: space-between;
                  padding-bottom: 20px;
                  border-bottom: 1px solid #eaedf3;

                  .right {
                    gap: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .dropDownOuterCon {
                      margin: 0px;
                      text-wrap: nowrap;
                    }
                    .search_bar {
                      display: flex;
                      margin-top: auto;
                      align-items: center;
                      position: relative;
                      width: 300px;
                      height: 43px;
                      z-index: 1;
                      .inputBoxMain {
                        margin: 0px;
                        p {
                          display: none;
                        }
                      }
                      svg {
                        position: absolute;
                        z-index: 2;
                        right: 10px;
                        top: 15px;
                      }
                    }
                  }
                }
                .bottom {
                  justify-content: space-evenly;
                  width: 100%;
                  display: flex;
                  padding: 10px 20px;
                }
              }
              .container {
                table {
                  width: 100%;
                  .thead {
                  }
                  th {
                    text-align: left;
                    color: #9ea0a5;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px; /* 150% */
                    text-transform: uppercase;
                    padding: 15px 20px;
                    border-top: 1px solid #eaedf3;
                  }
                  tbody {
                    tr {
                      td {
                        text-wrap: nowrap;
                        border-top: 1px solid #eaedf3;
                        padding: 24px 20px;
                        color: #3e3f42;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 157.143% */
                        .unittype_wrapper {
                          display: flex;
                          align-items: center;
                          gap: 20px;
                          .type {
                            display: flex;
                            gap: 10px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .budgetComparision-B {
            display: flex;
            flex-direction: column;
            gap: 40px;
            .dropDownOuterCon {
              margin: 0px;
              p {
                display: none;
              }
            }
            .budgetComparision_bars {
              border: 1px solid #eaedf3;
              border-radius: 4px;
              // display: flex;
              .header_bar {
                padding: 20px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .right {
                  .search_bar {
                    width: 150px;
                    display: block;
                    align-items: center;
                    position: relative;
                    z-index: 1;
                    .inputBoxMain {
                      margin: 0px;
                    }
                    svg {
                      position: absolute;
                      z-index: 2;
                      right: 10px;
                      top: 15px;
                    }
                  }
                }
              }
              .container {
                .rows {
                  padding: 40px 20px;
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  gap: 20px;
                  .row {
                    display: flex;
                    gap: 30px;
                    width: 100%;
                    p {
                      min-width: 175px;
                      color: #3e3f42;
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                    }
                    .p {
                      color: #9ea0a5;
                      text-align: right;
                      font-family: Roboto;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 157.143% */
                    }
                  }
                }
              }
            }
            .buttons_wrapper {
              display: flex;
              gap: 20px;
            }
          }
        }
        .propertyVsBenchmark {
          display: flex;
          flex-direction: column;
          gap: 40px;
          h2 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 155.556% */
          }
          .propertyVsBenchmark-T {
            .propertyVsBenchmark_table {
              border: 1px solid #eaedf3;
              border-radius: 4px;
              .header_bar {
                // padding: 20px;
                // display: flex;
                // align-items: center;
                // justify-content: space-between;
                .top {
                  padding: 20px 20px;
                  display: flex;
                  align-items: flex-end;
                  width: 100%;
                  justify-content: space-between;
                  padding-bottom: 20px;
                  border-bottom: 1px solid #eaedf3;

                  .right {
                    gap: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    .dropDownOuterCon {
                      margin: 0px;
                      text-wrap: nowrap;
                      p {
                        display: none;
                      }
                    }
                    .search_bar {
                      display: flex;
                      margin-top: auto;
                      align-items: center;
                      position: relative;
                      width: 300px;
                      height: 43px;
                      z-index: 1;
                      .inputBoxMain {
                        margin: 0px;
                        p {
                          display: none;
                        }
                      }
                      svg {
                        position: absolute;
                        z-index: 2;
                        right: 10px;
                        top: 15px;
                      }
                    }
                  }
                }
                .bottom {
                  width: 63%;
                  display: flex;
                  padding: 10px 20px;
                  align-items: center;
                  margin-left: auto;
                  svg {
                    margin-left: 30px;
                  }
                  h3 {
                    &:nth-child(2) {
                      margin-left: 200px;
                    }

                    &:nth-child(4) {
                      margin-left: 80px;
                    }
                    text-align: center;
                  }
                }
              }
              .container {
                table {
                  width: 100%;

                  .thead {
                  }
                  th {
                    text-align: left;
                    color: #9ea0a5;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px; /* 150% */
                    text-transform: uppercase;
                    padding: 15px 20px;
                    border-top: 1px solid #eaedf3;
                  }
                  tbody {
                    tr {
                      td {
                        &:nth-child(6) {
                          background-color: #f5f5f7;
                        }
                        &:nth-child(7) {
                          background-color: #f5f5f7;
                        }
                        text-wrap: nowrap;
                        border-top: 1px solid #eaedf3;
                        padding: 24px 20px;
                        color: #3e3f42;
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 157.143% */
                        .title {
                          display: flex;
                          width: 200px;
                          justify-content: space-between;
                          svg {
                            margin-right: -20px;
                          }
                        }
                        .assetFinalValue {
                          display: flex;
                          width: 100px;
                          justify-content: space-between;
                          svg {
                            margin-right: -20px;
                          }
                        }
                        .unittype_wrapper {
                          display: flex;
                          align-items: center;
                          gap: 20px;
                          .type {
                            display: flex;
                            gap: 10px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .budgetGenerating-container {
      padding: 20px;
      .budgetGenerating-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .left {
          h1 {
            color: #000;

            /* h5/Medium 18px */
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
        .right {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
          .search_bar {
            width: 500px;
            display: block;
            align-items: center;
            position: relative;
            z-index: 1;
            svg {
              position: absolute;
              z-index: 2;
              right: 10px;
              top: 15px;
            }
            .inputBoxMain {
              margin-top: 0px;
            }
          }
          .dropDownOuterCon {
            margin: 0px !important;
            p {
              display: none;
            }
          }
        }
      }
      .budgetGenerating-tab-content {
        display: flex;
        justify-content: space-between;
        .generate_budget_card {
          border: 1px solid #eaedf3;
          border-radius: 6px;
          padding: 20px;
          width: 350px;
          .top {
            display: flex;
            align-items: start;
            flex-direction: column;
            gap: 10px;
            h3 {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
            }
            p {
              color: #6b6c6f;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
            }
          }
          .bottom {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid #eaedf3;
            .PrimaryButton {
              margin-top: 0px !important;
            }
          }
        }

        .prediction_card {
          border: 1px solid #eaf1f7;
          border-radius: 6px;
          max-width: 350px;
          max-height: 492px;
          justify-content: center;
          padding: 10px 12px;
          gap: 20px;
          display: flex;
          flex-direction: column;
          .prediction_card-T {
            display: flex;
            justify-content: center;
          }
          .prediction_card-M {
            padding: 0px 10px;
            border-bottom: 1px solid #f2f4f7;
            .text_wrapper {
              h3 {
                color: #3e3f42;
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px; /* 155.556% */
              }
              .p {
                color: #9ea0a5;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
              }
              margin-bottom: 15px;
            }
            .details_wrapper {
              display: flex;
              gap: 5px;
              justify-content: space-between;
              .left,
              .right {
                display: flex;
                align-items: flex-start;
                gap: 10px;
                img,
                svg {
                  margin-top: 5px;
                }
              }
              .text_wrapper {
                span {
                  color: #9ea0a5;
                  font-family: Roboto;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 18px; /* 150% */
                }
                p {
                  color: #3e3f42;
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 22px; /* 157.143% */
                }
              }
            }
          }
          .prediction_card-B {
            .prediction_card-B-T {
              display: flex;
              justify-content: space-between;

              .PrimaryButton {
                margin-top: 0px;
                padding: 5px 40px;
              }
            }
            .prediction_card-B-B {
              padding: 25px 0px;
              display: flex;
              gap: 10px;
              align-items: center;
              p {
                color: #3e3f42;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
              }
            }
          }
        }
      }
    }
  }
}
.analysis-container {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: Roboto;
  }

  padding-top: 20px;
  .analysis-header {
    border-bottom: 1px solid #eaedf3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .analysis-title {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      color: #3e3f42;
      padding: 20px;
    }

    .analysis-tab-container {
      display: flex;
      column-gap: 20px;
      .analysis-tab {
        cursor: pointer;
        display: flex;
        column-gap: 10px;
        align-items: center;
        padding: 20px;

        img {
          width: 20px;
          height: 20px;
          fill: #9ea0a5;
        }
        h4 {
          color: #6b6c6f;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
        }
        &.active {
          border-bottom: 2px solid #7d55c7;
          img {
            fill: #7d55c7;
          }
          h4 {
            color: #7d55c7;
          }
        }
      }
      .analysis-tab:not(:last-child) {
        border-right: 1px solid #eaedf3;
      }
    }
  }
}

.maintenance-container {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: Roboto;
  }

  padding-top: 20px;
  .maintenance-header {
    border-bottom: 1px solid #eaedf3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .maintenance-title {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      color: #3e3f42;
      padding: 20px;
    }

    .maintenance-tab-container {
      display: flex;
      column-gap: 20px;
      .maintenance-tab {
        cursor: pointer;
        display: flex;
        column-gap: 10px;
        align-items: center;
        padding: 20px;

        img {
          width: 20px;
          height: 20px;
          fill: #9ea0a5;
        }
        h4 {
          color: #6b6c6f;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
        }
        &.active {
          border-bottom: 2px solid #7d55c7;
          img {
            fill: #7d55c7;
          }
          h4 {
            color: #7d55c7;
          }
        }
      }
      .maintenance-tab:not(:last-child) {
        border-right: 1px solid #eaedf3;
      }
    }
  }
  .maintenance-content {
    .utilities_wrapper {
      width: 100%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 60px;
    }
    .usage_cards {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        scale: 1;
        width: 100%;
      }
    }
    .highestConsuming {
      img {
        width: 100%;
      }
    }
  }

  .maintenanceTab {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 20px;
    .OpenServiceTable {
      border: 1px solid #eaedf3;
      border-radius: 4px;
      .header_bar {
        .left {
          text-wrap: nowrap;
          span {
            color: var(--LP-gray, #9ea0a5);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 128.571% */
          }
        }
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .right {
          display: flex;
          gap: 20px;
          width: 100%;
          align-items: center;
          justify-content: flex-end;
          .search_bar {
            display: flex;
            align-items: center;
            position: relative;

            z-index: 1;
            .inputBoxMain {
              margin: 0px;
            }
            svg {
              position: absolute;
              z-index: 2;
              right: 10px;
              top: 15px;
            }
          }
        }
      }
      .container {
        table {
          width: 100%;
          .thead {
          }
          th {
            text-align: left;
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 150% */
            text-transform: uppercase;
            padding: 15px 20px;
            border-top: 1px solid #eaedf3;
          }
          tbody {
            tr {
              td {
                text-wrap: nowrap;
                border-top: 1px solid #eaedf3;
                padding: 24px 20px;
                color: #3e3f42;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
                .unittype_wrapper {
                  display: flex;
                  align-items: center;
                  gap: 20px;
                  .type {
                    display: flex;
                    gap: 10px;
                  }
                }
              }
            }
          }
        }
      }
      .pagination {
        gap: 20px;
        display: flex;
        align-items: center;
        button {
          all: unset;
          padding: 5px 10px;
          &.active {
            padding: 5px 10px;
            background: #eaedf3;
          }
        }
      }
    }
    .maintenanceTab-T {
      flex: 2 1 auto;
      gap: 40px;
      display: flex;
      .left {
        width: 100%;
      }
    }
    .maintenanceTab-B {
      img {
        width: 100%;
      }
    }
  }
}

.inventory-container {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: Roboto;
  }

  padding-top: 20px;

  .inventory-title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #3e3f42;
    padding: 20px;
    border-bottom: 1px solid #eaedf3;
  }
}

.market-intelligence {
  background-color: #fbfbfd;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  .graph-wrapper {
    background-color: #ffffff;
    border: 1px solid #eaedf3;
    border-radius: 5px;
    h1 {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      color: #3e3f42;
      padding: 20px 0px 20px 20px;
    }
    .market-analysis-grids {
      padding: 20px;
      display: grid;
      grid-template-columns: 48% 1.5fr 0.4fr;
      grid-gap: 40px;
      margin-bottom: 40px;

      .market-analysis-grid-1 {
        // height: 250px;
        width: 100%;
        // object-fit: contain;
        // display: flex;
        flex: 1;
      }
      .market-analysis-grid-2 {
        display: flex;
        justify-content: space-between;
        height: 250px;
        width: 100%;
        column-gap: 10px;
        img {
          height: 100%;
          object-fit: contain;
        }
        .graph-labels {
          padding-top: 10px;
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          justify-content: end;

          .label {
            display: flex;
            align-items: center;
            column-gap: 6px;
            padding-left: 5px;
            p {
              width: 6px;
              height: 6px;
              border-radius: 100%;
            }
            h4 {
              font-family: Roboto;
              font-size: 12px;
              font-weight: 500;
              color: #9ea0a5;
              text-transform: uppercase;
            }
          }
        }
      }
      .market-analysis-grid-3 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 230px;
        column-gap: 10px;
        align-items: end;
        padding-top: 20px;

        p {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          color: #fff;
          padding: 5px 8px;
          background-color: #8ec14b;
          width: 28px;
        }
        h1 {
          font-family: Roboto;
          font-size: 38px;
          font-weight: 300;
          line-height: 60px;
          color: #3e3f42;
        }
        h6 {
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          color: #9ea0a5;
        }
        h2 {
          font-family: Roboto;
          font-size: 22px;
          font-weight: 400;
          line-height: 32px;
          color: #3e3f42;
        }
      }
    }
  }
}

.market-supply {
  display: grid;
  grid-template-columns: 70% 1fr;

  .supply-main {
    img {
      width: 100%;
    }
    .asset-tab-bar {
      display: flex;

      padding: 0px 20px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eaedf3;
      .asset-tabs {
        display: flex;
        column-gap: 20px;
        padding-top: 20px;
        a {
          font-family: Roboto;
          color: #6b6c6f;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          padding-bottom: 10px;
          &.active {
            border-bottom: 2px solid #7d55c7;
            color: #3e3f42;
          }
        }
      }
      .selected-asset-options {
        display: flex;
        justify-content: space-between;
        column-gap: 20px;

        .option-btn {
          display: flex;
          align-items: center;
          column-gap: 10px;
          border-radius: 25px;
          padding: 10px 15px;
          cursor: pointer;
          img {
            width: 20px;
            height: 20px;
            fill: #6b6c6f;
            color: #6b6c6f;
          }
          p {
            color: #3e3f42;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
          }

          &.active {
            background-color: #efeff3;
            p {
              color: #7d55c7;
            }
            img {
              fill: #7d55c7;
              color: #7d55c7;
            }
          }
        }
      }
    }
  }
  .supply-sidebar {
    padding: 40px 20px;
    .my-asset {
      h4 {
        color: #9ea0a5;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      .my-asset-card {
        display: flex;
        column-gap: 10px;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;

        img {
          width: 93px;
          height: 60px;
        }
        .my-asset-card-details {
          display: flex;
          flex-direction: column;
          row-gap: 5px;
          h2 {
            color: #6b6c6f;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
          }
          p {
            color: #9ea0a5;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
          }
          h6 {
            color: #6b6c6f;
            font-weight: 500;
            font-size: 16px;
          }
        }

        &.active {
          background: linear-gradient(#7d55c7, #7d55c7cc);
          border: 1px solid #eaedf3;
          h2,
          p,
          h6 {
            color: white;
          }
        }
      }
    }
    .similar-assets {
      margin-top: 20px;
      .similar-asset-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
          color: #9ea0a5;
          font-weight: 500;
          font-size: 12px;
          text-transform: uppercase;
          margin-bottom: 10px;
        }
      }
      .similar-asset-card-container {
        overflow-y: scroll;
        .similar-asset-card {
          cursor: pointer;
          display: flex;
          column-gap: 10px;
          padding: 10px;
          border-radius: 2px;

          img {
            width: 100px;
            height: 88px;
          }
          .similar-asset-card-details {
            display: flex;
            flex-direction: column;
            row-gap: 5px;
            h2 {
              color: #6b6c6f;
              font-weight: 500;
              font-size: 14px;
              line-height: 14px;
            }
            .address {
              display: flex;
              column-gap: 10px;
              align-items: center;

              .address-units {
                background-color: #eaeaea;
                color: #6b6c6f;
                padding: 5px 10px;
                text-align: center;
                font-size: 10px;
              }
              .address-main {
                color: #9ea0a5;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                max-width: 60%;
              }
            }
            .asset-costing {
              display: flex;
              justify-content: space-between;
              align-items: center;
              h6 {
                color: #3e3f42;
                font-weight: 500;
                font-size: 16px;
                line-height: 28px;
              }
              .asset-icons {
                display: flex;
                column-gap: 10px;
                img {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }

          &.active {
            background-color: #f9f7fb;
            border-left: 2px solid #7d55c7;
          }
        }
      }
    }
  }
}

.market-lease {
  gap: 40px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  .market-lease-T {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    width: 100%;
    .left {
      width: 60%;
    }
  }
  .market-lease-M,
  .market-lease-B {
    width: 100%;
  }
  img {
    width: 100%;
  }
}

.my-asset-container {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: Roboto;
  }
  padding: 20px;

  .my-asset-content {
    display: grid;
    grid-template-columns: 60% 1fr;
    gap: 40px;
    .asset-left-container {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      overflow-y: auto;

      .asset-subheading {
        font-weight: 500;
        color: #9ea0a5;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
      }

      .asset-heading {
        font-weight: 400;
        color: #3e3f42;
        font-size: 26px;
        line-height: 16px;
      }

      .asset-address {
        color: #9ea0a5;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
      .asset-info {
        font-weight: 400;
        color: #6b6c6f;
        font-size: 14px;
        line-height: 22px;
        max-width: 92%;
      }
    }
    .asset-right-container {
      .pricing {
        padding: 20px;
        border-left: 1px solid #d8d8d8;
        h2 {
          font-weight: 500;
          color: #9ea0a5;
          font-size: 12px;
          line-height: 18px;
          text-transform: uppercase;
          margin-bottom: 15px;
        }
        h1 {
          font-weight: 500;
          color: #3e3f42;
          font-size: 20px;

          .per-feet {
            color: #6b6c6f;
            font-weight: 400;
            font-size: 14px;
            margin-left: 10px;
          }
          .percent {
            font-weight: 400;
            font-size: 14px;
            margin-left: 10px;
          }
        }

        .economic-pricing {
          display: flex;
          column-gap: 20px;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
      .property-features {
        padding: 20px;
        h2 {
          font-weight: 500;
          color: #9ea0a5;
          font-size: 12px;
          line-height: 18px;
          text-transform: uppercase;
          margin-bottom: 15px;
        }
        .features-container {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          .feature-entity {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .feature-name {
              display: flex;
              align-items: center;
              column-gap: 20px;
              img {
                width: 20px;
                height: 20px;
              }
              h6 {
                color: #6b6c6f;
                font-size: 14px;
                font-weight: 400;
              }
            }
            p {
              color: #3e3f42;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
        .floor-btn-wrapper {
          margin-top: 30px;
          width: 100%;
        }
      }
    }
  }
  .utilities {
    margin-top: 40px;
    h2 {
      color: #3e3f42;
      font-weight: 500;
      font-size: 18px;
    }
    .utilities-tab-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eaedf3;
      .utilities-tabs {
        display: flex;
        column-gap: 20px;
        padding-top: 20px;
        a {
          font-family: Roboto;
          color: #6b6c6f;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          padding-bottom: 10px;
          &.active {
            border-bottom: 2px solid #7d55c7;
            color: #3e3f42;
          }
        }
      }
    }
  }
  .utilities-tab-content {
    padding: 20px 0px;
  }
}

.utilities-arrangement-container {
  .arrangement-header {
    display: grid;
    grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
    padding: 10px;
    border-bottom: 1px solid #eaecee;
    h2 {
      font-family: Roboto;
      color: #9ea0a5;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
  }
  .arrangement-content {
    display: grid;
    grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
    padding: 10px;
    border-bottom: 1px solid #eaecee;
    p {
      font-family: Roboto;
      color: #3e3f42;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .cost-change-legends {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      font-family: Roboto;
      color: #3e3f42;
      font-size: 14px;
      font-weight: 500;
    }
    .legends {
      display: flex;
      column-gap: 10px;
      align-items: center;
      p {
        font-family: Roboto;
        color: #9ea0a5;
        text-transform: uppercase;
        font-size: 12px;
        display: flex;
        align-items: center;
        column-gap: 5px;
        .legend-color-indicator {
          width: 6px;
          height: 6px;
          border-radius: 100%;
        }
      }
    }
  }
}

.line {
  background-color: #eaedf3;
  height: 0px;
  border: solid 1px #eaedf3;
  width: 100%;
}

.market_analiysis {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.market_analiysis_left {
  border: 1px solid black;
  padding: 10px;
  width: 400px;
  height: auto;
  /* align-items: center; */
  height: 30vh;
  justify-content: center;
  /* display: flex; */
  margin: -1px;
}

.market_analiysis_right {
  border: 1px solid black;
  padding: 10px;
  width: 400px;
  height: auto;
  /* align-items: center; */
  height: 30vh;
  justify-content: center;
  /* display: flex; */
  margin: -1px;
}

.container-AssetDashboard {
  max-width: 600px;
  margin: auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.active-deals-AssetDashboard,
.expected-revenue {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 5px;
}

.active-deals-AssetDashboard {
  background-color: #e7f3fe;
  display: flex;
  flex-direction: column;
  gap: 14px;
  border-left: 5px solid #2196f3;
}

.expected-revenue {
  background-color: #e8f5e9;
  display: flex;
  flex-direction: column;
  gap: 14px;
  border-left: 5px solid #4caf50;
}

.number,
.amount {
  font-size: 2em;
  margin: 10px 0;
  color: #333;
}

.change,
.change-revenue {
  color: green;
  font-weight: bold;
}

.partnership,
.payout {
  color: #666;
}


// Simmar effect
.shimmer {
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 10%,
    rgba(255, 255, 255, 0.8) 20%,
    rgba(255, 255, 255, 0) 30%
  );
  animation: shimmer 1.5s infinite;
  background-size: 200% 100%;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.loading-placeholder {
  height: 20px; /* Adjust this according to your placeholder size */
  margin-bottom: 10px; /* Space between loading blocks */
  background-color: #e0e0e0;
  border-radius: 5px; /* Rounds the edges */
}


.basicSelect {
  /* Basic styles for the select element */
  font-size: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff; /* Optional: ensures background color */
}

.basicOption {
  /* Basic styles for the options (note: styling is limited) */
  background-color: #fff; /* Optional: ensures background color */
}


.Table-container{
  display: flex !important;
  flex-direction: column !important;
  gap: 24px !important;
  margin-bottom: 30px !important;
}

// AssetDashboard_marketanalysis

.AssetDashboard_marketanalysis{
  width: 193px;
height: 1px;
flex-shrink: 0;
background: #EAEDF3;
}

// pgaination - 

.Management_Pagination{
  margin-top: 16px;
  text-align: right;
  margin-bottom: 16px;
  display: flex;
}