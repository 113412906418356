.menu-arrow-icon {
  margin-left: auto;
  font-size: 18px;
  color: #999;
}

.breadcrumb {
  width: fit-content;
  padding: 10px;
  display: flex;
  gap: 14px;
  span {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #6b6a6f;
  }
}
.new-chat-button {
  padding: 10px;
  span {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #3e3f42;
    margin-left: 10px;
  }
}

.BeartacticAI-left-menu {
  cursor: pointer;
  box-sizing: border-box;
  // width: 270px;
  height: 46px;
  flex-shrink: 0;
  display: flex;
  gap: 20px;
  align-items: center;

  &.BeartacticAI-menu-active {
    background: rgba(125, 85, 199, 0.05);
    box-shadow: 3px 0px 0px 0px #6758f3 inset;
    color: var(--purple-pantone-2665-c, #7d55c7);
    .clickable-svg {
      &.clicked {
        rect {
          fill: #602fbc;
        }
        path {
          fill: #602fbc;
          fill-opacity: 1;
        }
      }
    }
  }

  &.BeartacticAI-menu-active-sort {
    background: rgba(125, 85, 199, 0.05);
    color: var(--purple-pantone-2665-c, #7d55c7);
    .clickable-svg {
      &.clicked {
        rect {
          fill: #602fbc;
        }
        path {
          fill: #602fbc;
          fill-opacity: 1;
        }
      }
    }
  }

  .left-menu-title {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; 
  }
}
