* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.deals-room-center {
  width: 1120px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
}

.asset-center-con {
  width: 1120px;
}

.deals-room-center-header {
  height: 67px;
  background: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px !important;
}

.dataFilter {
  display: flex;
  width: 100%;
  justify-content: right;

  #dataFilter {
    padding: 9px;
  }
}

.acq-head-left {
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    color: #3e3f42;
    leading-trim: both;
    text-edge: cap;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
  }
}

.acq-head-right {
  display: flex;
  align-items: center;
  gap: 30px;
}

.acq-head-left > div {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-right: 20px;
  border-right: 1px solid #eaedf3;
}

// ------------------------------------------
// ----Asset main container ----------------
// -----------------------------------------

.asset-main-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px;
}
.AssetInformation-main-Container {
  margin-top: 77px;
}

.asset-main-center {
  width: 737px;
  min-height: 524px;
  padding: 0 !important;
  // background-color: rgba(15, 160, 227, 0.623);s
}

.asset-main-right {
  width: 350px;
  height: 524px;
  position: sticky !important;
  // top: 70px !important;
  // background-color: rgba(15, 227, 15, 0.623);
}

.asset-img-hero {
  width: 730px;
  // height: 430px;
  flex-shrink: 0;

  // background: linear-gradient(
  //     180deg,
  //     rgba(0, 0, 0, 0) 53.96%,
  //     rgba(0, 0, 0, 0.25) 100%
  //   ),
  //   url(<path-to-image>), lightgray 50% / cover no-repeat;
}

.asset-img-hero > img {
  border-radius: 4px;
}

.asset-nav {
  width: 730px !important;
  height: 40px !important;
  margin: 20px 0 0 0;
  border-bottom: 1px solid #6b6c6f;
  width: 100%;
  margin-bottom: 17px;
}

.asset-nav span {
  font-size: 12px !important;
  font-weight: 500;
}

.property-d > span {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 155.556% */
}

.property-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 29px;
}

.property-wrapper-left {
  width: 445px;
}

.property-wrapper-left {
  // width: 225px;

  .ant-collapse {
    border: 1px solid #eaedf3 !important;
    margin-bottom: 20px;
    overflow: hidden;

    .ant-collapse-header {
      background-image: linear-gradient(to top, #f7f8fa, #fefefe);
      align-items: center;
    }
  }
}

.property-wrapper-left,
.property-wrapper-right {
  > span:first-child {
    margin-bottom: 17px;
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
    // text-transform: uppercase;
    display: block;
    // margin: 20px 0 17px 0;
  }
}

.property-wrapper-right {
  width: 20vw;
}

.property-description {
  margin-top: 17px;
  display: block;
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  border-bottom: 1px solid #eaedf3;
  padding-bottom: 22px;
  margin-bottom: 30px;
}

.property-img-wrapper {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 10px;

  img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: center;
  }
}

.property-f-wrapper {
  width: 150px;
}

.property-f-title {
  margin-bottom: 17px;
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-transform: uppercase;
  display: block;
  margin: 20px 0 17px 0;
}

.f-w-c {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
}

.f-w-c > div:first-child {
  display: flex;
  gap: 15px;
}

.f-w-c > div:first-child > div > span {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.f-w-c > div:last-child > span {
  color: #3e3f42;
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.d-heading {
  display: flex;
  gap: 16px;
  margin-top: 15px;
  margin-bottom: 20px;
  cursor: pointer;

  > span {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
  }

  > span:last-child {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
  }
}

.document-wrapper {
  margin-bottom: 30px;
  min-width: 445px;
  min-height: 400px;

  .d-w-h {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
    text-transform: uppercase;
  }
}

.land-doc-wrapper {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
}

.land-doc-card {
  box-sizing: border-box;
  width: 140px;
  // height: 268px;
  flex-shrink: 0;
  padding: 15px 15px;
  border-radius: 4px;
  border: 1px solid #eaedf3;
}

.land-doc-card > span {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  margin-top: 10px;
}

.land-doc-card > div {
  margin-top: 13px;
  display: flex;
  align-items: center;
  // flex-wrap: wrap;
  justify-content: space-between;
}

.divider {
  border: 1px solid #eaedf3;
}

.utilities {
  width: 100% !important;
  margin-bottom: 40px;
  margin-top: 30px;

  .utilities-w-p {
    padding: 24px 0 0 0 !important;
  }

  .utilities-main-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }

  .amenities-wrapper {
    width: 444px;
    height: 226px;
    flex-shrink: 0;

    > span {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      /* 150% */
      text-transform: uppercase;
      display: block;
      margin-bottom: 16px;
      margin-top: 22px;
    }

    .amenities-w-main {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      div {
        border-radius: 4px;
        border: 1px solid #eaedf3;
        background: #fbfbfd;
        padding: 4px 12px;
        justify-content: center;
        align-items: center;

        margin-bottom: 8px;

        > span {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }
      }
    }
  }

  .jurisdiction-wrapper {
    div {
      display: flex;
      gap: 50px;
      margin-bottom: 8px;

      > span {
        display: block;
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      > span:last-child {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
    }
  }

  .value-taxes-main {
    display: flex;
    gap: 30px;

    .valuation-taxes-wrapper {
      width: 350px;
      height: 191px;
      flex-shrink: 0;
      padding: 25px 30px 29px 28px;
      border-radius: 0px 4px 4px 0px;
      border: 1px solid #eaedf3;
      background: #fff;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);

      > span {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        /* 150% */
        text-transform: uppercase;
      }

      .valuation-taxes-wrapper-main {
        margin-top: 24px;

        .divider {
          border: 1px solid #eaedf3;
          margin: 18px 0;
        }

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          div {
            display: flex;
            align-items: center;
            gap: 22px;

            > span {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
              /* 157.143% */
            }
          }

          span {
            color: #3e3f42;
            text-align: right;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            /* 150% */
          }
        }
      }
    }
  }
}

// ----market survay---------------
// ------------              --------

.news-s-wrapper {
  margin-top: 30px;
}

.market-sur-heading {
  margin-bottom: 16px;
  margin-top: 40px;
}

.market-sur-heading > span {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 155.556% */
}

.social-net-wrapper {
  padding-top: 30px;
  display: flex;
  gap: 30px;

  .net {
    margin: 40px 0;
  }

  .asset-review {
    width: 520px;

    .rating-wrapper {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      flex-wrap: wrap;
      gap: 7px 60px;

      .rating-container {
        width: 230px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > span {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }

        > div > span {
          color: #f9c125;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          /* 157.143% */
        }

        > div {
          display: flex;
          gap: 10px;
        }
      }
    }

    .person-rating-wrapper {
      margin-top: 20px;

      span {
        display: block;
      }

      .person-r-upper {
        margin-bottom: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > .prof {
          > img {
            height: 38px;
            width: 38px;
            border-radius: 50%;
          }

          display: flex;
          gap: 12px;

          > div {
            span {
              display: block;
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              /* 157.143% */
            }

            > span:last-child {
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              /* 150% */
            }
          }
        }

        > div:last-child {
          text-align: right;

          > span {
            color: #9ea0a5;
            text-align: right;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
          }
        }
      }

      .person-r-center {
        margin-bottom: 22px;

        span {
          color: #6b6c6f;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }
      }
    }
  }

  .social-n-l {
    .social-heading {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      /* 150% */
      margin-bottom: 30px;
    }

    .social-n-card {
      margin-bottom: 20px;
      width: 520px;
      min-height: 235px;
      flex-shrink: 0;
      border-radius: 4px;
      border: 1px solid #e2e5ed;
      background: #fff;

      .d {
        margin-top: 11px;
        margin-bottom: 0;
      }

      .s-n-c-main {
        padding: 20px 20px 0px 20px;
      }

      .n-card-upper {
        margin-bottom: 11px;
        display: flex;
        justify-content: space-between;
      }

      .n-c-u-l {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        span {
          display: block;
        }

        .ver {
          width: 38px;
          height: 38px;
          border-radius: 50%;
        }

        > div > span {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 19px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
        }

        > div > span:last-child {
          color: var(--LP-gray, #9ea0a5);
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          /* 157.143% */
        }
      }

      .n-card-center {
        > span {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }
      }

      .n-card-down {
        margin-top: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
          display: flex;
          align-items: center;
          gap: 16px;

          > div {
            display: flex;
            align-items: center;
            gap: 9px;
          }
        }

        span {
          color: var(--LP-gray, #9ea0a5);
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          /* 183.333% */
        }
      }
    }
  }

  .s-n-c-main-down {
    padding: 7px 19px 9px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
      gap: 9px;

      > span {
        color: var(--3-fa-2-f-7-links-informational-blue, #3fa2f7);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 183.333% */
      }
    }
  }

  .social-n-r {
    .social-filter-heading {
      margin-top: 9px;
      color: #3e3f42;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 150% */
    }

    > div > span {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      /* 150% */
      text-transform: uppercase;
    }

    .show-filter {
      margin-top: 19px;

      .checkbox-con {
        margin-top: 8px;

        > span {
          display: block;
          margin-left: 28px;
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }
      }
    }
  }
}

.page-nxt-slider-inner {
  display: flex;
  align-items: center;
  margin-bottom: 19px;
}

.page-nxt-slider-inner > div {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-nxt-slider-inner > div:nth-child(4) {
  background-color: #eaedf3;
}

.table-wrapper {
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  margin-bottom: 22px;
}

.table-wrapper div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 17px 0;
  border-bottom: 1px solid #6b6c6f;
}

.table-wrapper div span {
  display: block;
}

.table-wrapper > div:first-child > span {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-transform: uppercase;
}

.table-wrapper > div:not(.table-h) > span {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

// .table-wrapper > div:not(.table-h) {
//   padding-right: 30px;
// }

.wrapper-heading {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.table-nav {
  margin-bottom: 0 !important;
  width: 730px;
  border-bottom: 1px solid #eaedf3;
}

.property-history-wrapper {
  margin-bottom: 40px;

  .property-wrapper-main {
    padding: 20px 30px 30px 30px;

    .price-history-upper {
      display: flex;
      gap: 40px;

      .price-h-card {
        width: 205px;
        height: 86px;
        flex-shrink: 0;

        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        > div:first-child {
          margin-bottom: 10px;

          > span {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 171.429% */
          }

          > span:last-child {
            color: #9ea0a5;
            text-align: right;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
          }
        }

        > div:nth-child(2) {
          margin-bottom: 4px;

          > span {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 26px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            /* 115.385% */
          }

          div {
            display: flex;
            align-items: center;
            gap: 8px;

            span {
              color: #34aa44;
              text-align: right;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              /* 157.143% */
            }
          }
        }

        > div:last-child {
          > span {
            color: #9ea0a5;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 150% */
          }
        }
      }

      .divider {
        width: 1px;
        height: 82px;
        flex-shrink: 0;
        border: 1px solid #eaedf3;
      }
    }
  }
}

.p-table-wrapper {
  width: 730px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // padding: 12px 30px;
  box-sizing: border-box;

  > div {
    height: 43px;
    padding: 0 30px;
    border-bottom: 1px solid #eaedf3;
    display: flex;
    align-items: center;
  }
}

.p-table-header {
  display: flex;
  justify-content: space-between;

  > div > span {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
    text-transform: uppercase;
  }
}

.flex-body {
  width: 220px;
  display: flex;
  justify-content: space-between;
}

.p-table-body {
  display: flex;
  justify-content: space-between;

  > div > span {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
  }
}

.explore-wrapper {
  width: 730px;
}

.explore-body-right {
  width: 497px;
  height: 370px;
  padding: 20px 24px;
}

.explore-body-wrapper {
  display: flex;
}

.explore-body-left {
  width: 255px;

  .inp {
    display: flex;
    gap: 20px;

    > img {
      margin-top: 35px;
    }
  }

  h1 {
    margin-top: 14px;
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
  }
}

.explore-input img {
  border-radius: 6px;
  background: linear-gradient(to right, rgb(255 252 252 / 48%), #eeeeee);
  padding: 0 15px;
}

.explore-input {
  margin-top: 16px !important;

  input {
    padding: 8px 29px 8px 10px;

    &::placeholder {
      padding-right: 25px;
    }
  }
}

.ass-nav {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 200% */
  text-transform: capitalize;

  .ut-nav-active {
    color: #3e3f42;
    font-weight: 500;
  }
}

.ut-nav {
  margin-bottom: 0;

  > span {
    font-size: 14px !important;
  }

  .ut-nav-active {
    color: #3e3f42;
    font-weight: 500;
  }
}

.explore-checkbox {
  display: flex;
  align-items: center;
  margin-top: 11px;

  h2 {
    color: #6b6c6f;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
  }
}

.explore-ways {
  margin-top: 20px;

  span {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
    text-transform: uppercase;
    display: block;
    margin-bottom: 9px;
  }

  div {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.explore-para {
  color: var(--LP-mid-gray, #6b6c6f);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 16px;
  display: block;

  span {
    color: var(--LP-black, #3e3f42);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
}

.explore-refresh {
  display: flex;
  gap: 10px;
  margin-top: 16px;
  align-items: center;

  span {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
  }
}

.educational-left {
  width: 255px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div:first-child {
    display: flex;
    gap: 16px;
  }

  .educational-card {
    width: 255px;
    height: 61px;
    padding: 10px 10px 10px 16px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      display: block;
    }

    > div {
      > span {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }

      > span:last-child {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 150% */
      }
    }

    > div:last-child {
      > span {
        color: #9ea0a5;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 150% */
      }
    }
  }

  .educational-card:nth-child(2) {
    background: rgba(125, 85, 199, 0.05);
  }

  .potential {
    display: flex;
    gap: 16px;

    .edu-l {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    .edu-r {
      > div {
        display: flex;
        gap: 10px;

        span {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          /* 157.143% */
        }
      }

      > div:last-child {
        display: block;

        > span {
          display: block;
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }

        > span:last-child {
          color: #6b6c6f;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }
      }
    }

    .mall {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      background: #da77f2;
      border-radius: 50%;

      img {
        height: 25px;
      }
    }
  }
}

.building-wrapper {
  display: flex;
  padding: 30px 30px 30px 0;
  border-bottom: 1px solid #eaedf3;

  .building-wrapper-left {
    width: 380px;
  }

  .building-wrapper-right {
    width: 320px;
  }

  .b-w-r-h {
    margin-bottom: 21px;

    span {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* 150% */
    }
  }
}

.built-card {
  .built-card-middle {
    margin-bottom: 26px;
    margin-top: 26px;
  }

  .built-card-bottom {
    > div {
      margin-bottom: 12px;
    }
  }
}

.property-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;

  span {
    display: block;
  }

  .p-l-l {
    display: flex;
    align-items: flex-start;
    gap: 15px;

    .p-l-l-s-f {
      color: #6b6c6f;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }

    .p-l-l-s-l {
      color: var(--LP-black, #3e3f42);
      font-family: Roboto;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }
  }

  .p-l-r {
    .p-l-r-s-f {
      color: var(--red-pantone-1797-c, #cb333b);
      text-align: right;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }

    .p-l-r-s-l {
      color: var(--LP-gray, #9ea0a5);
      text-align: right;
      font-family: Roboto;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }
  }
}

.built-graph-wrapper {
  margin-top: 30px;
  border-bottom: 1px solid #eaedf3;
  padding-bottom: 20px;

  .built-graph-nav {
    display: flex;
    gap: 10px;

    div {
      display: flex;
      padding: 9px 16px;
      align-items: center;
      gap: 16px;
      border-radius: 30px;
      border: 1px solid var(--LP-very-light, #eaedf3);
      background: var(--ffffff-white, #fff);

      span {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
    }

    .active {
      span {
        color: var(--purple-pantone-2665-c, #7d55c7);
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        /* 157.143% */
      }

      img {
        filter: brightness(60%) sepia(190%) hue-rotate(204deg) saturate(1900%) contrast(0.9);
      }
    }
  }

  .b-g-g-s {
    margin-top: 30px;

    span {
      margin-left: 189px;
      color: var(--LP-black, #3e3f42);
      text-align: center;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      /* 150% */
    }
  }
}

.nearby-wrapper {
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  gap: 21px;

  span {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 150% */
    text-transform: uppercase;
  }

  .nearby-card-wrappper {
    margin-top: 25px;
    display: flex;
    gap: 25px;
    margin-bottom: 79px;
  }

  .nearby-card {
    box-sizing: border-box;
    width: 100px;

    .n-c-l {
      width: 100px;
      height: 3px;
      flex-shrink: 0;
      border-radius: 4px 4px 0px 0px;
      border: 1px solid #fff;
    }

    .n-c-b {
      margin-top: 12px;

      .n-c-b-h {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        /* 150% */
      }

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          color: #6b6c6f;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          /* 150% */
        }
      }
    }
  }
}

.deals-room-center2 {
  margin: 0 auto;
  margin-top: 10px;
  overflow: scroll;
  height: 100vh;
  width: 885px !important;
  margin-right: 400px;

  .deals-room-center-header {
    position: sticky;
    top: 0px;
    // border-radius: 10px;
  }

  .asset-main-container {
    margin: 0px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
    width: 100%;
    padding: 0px;

    // margin-top: 15px;
    .asset-main-center {
      background: #fff;
      padding: 0px 20px !important;
      width: 100%;
      // border-radius: 10px;
    }

    .asset-main-right {
      background: #fff;
      width: auto;
      // position: absolute;
      top: 10px;
      position: absolute !important;
      right: 30px;
      // border-radius: 10px;
      height: auto;

      .asset-card {
        border-radius: 10px;

        .asset-card-inner {
          padding: 0px 10px 0 10px;
        }
      }
    }
  }
}

.utility-table {
  border-collapse: collapse;
  width: 100%;
}

.utility-table th,
.utility-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  /* Lighter border */
}

.utility-table th {
  background-color: #ffffff;
  font-weight: 500;
  font-size: 500;
  color: #9ea0a5;
  font-weight: 0.7;
  /* Lighter background color */
}

.p-table-wrapper {
  margin-top: 20px;
  overflow-x: auto;
}

.p-table {
  width: 100%;
  border-collapse: collapse;
}

.p-table th,
.p-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.p-table th {
  background-color: #ffffff;
  // font-weight: bold;
  font-weight: 500 !important;
}

.p-table tbody tr:nth-child(even) {
  background-color: #ffffff;
}

// Responsive
/* For screens smaller than 1200px */
@media (max-width: 1199px) {
  .AssetInformation-deals-room-center {
    width: 100%;
    margin-left: 35px;
  }

  .asset-main-container {
    flex-direction: column;
    padding: 0 20px;
  }

  .asset-main-center {
    width: 100%;
  }

  .asset-main-right {
    width: 100%;
    position: static !important;
    margin-top: 20px;
  }
}

/* For screens smaller than 768px */
@media (max-width: 767px) {
  .deals-room-center-header {
    padding: 0 20px !important;
  }

  .property-wrapper {
    flex-direction: column;
  }

  .property-wrapper-left,
  .property-wrapper-right {
    width: 100%;
  }

  .document-wrapper {
    min-width: auto;
  }

  .utilities-main-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }

  .ut-nav > span {
    font-size: 12px !important;
  }

  .explore-body-wrapper {
    flex-direction: column;
  }

  .explore-body-right {
    width: 100%;
  }

  .explore-body-left {
    width: 100%;
  }

  .building-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .building-wrapper-left,
  .building-wrapper-right {
    width: 100%;
  }

  .nearby-card-wrappper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 25px;
  }

  .nearby-card {
    width: calc(50% - 12.5px);
  }
}

.AssetInformation-data {
  border: none;
  display: flex;
  gap: 20px;
  margin-top: 10px;

  span {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 125% */
    cursor: pointer;
  }
}

.asset_modal_container {
  margin-left: 0 !important;
  margin: 0 auto !important;
  width: fit-content !important;
  position: relative !important;

  .deals-room-center-header {
    position: static !important;
    width: 767px !important;
    margin-top: 12px;
  }

  .asset-main-container {
    position: relative !important;
    .asset-main-center {
      max-width: 767px !important;
    }
    .asset-main-right {
      width: fit-content !important;
      position: relative !important;
      right: 0 !important;
      height: fit-content !important;
      min-width: fit-content !important;
      top: 12px !important;
    }
    .off-market {
      height: fit-content !important;
    }
  }
}

.floorPlan_btn {
  display: flex;
  column-gap: 5px;
  padding: 10px 15px;
  // background-image: linear-gradient(to top, #f7f8fa, #fefefe);
  align-items: center;
  border: 1px solid #e2e5ed;
  border-radius: 5px;
  font-family: Roboto;
  color: #3e3f42;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  justify-content: center;
  margin-top: 15px;
}

.invite-button {
  display: flex;
  column-gap: 5px;
  padding: 10px 15px;
  background-image: linear-gradient(to top, #f7f8fa, #fefefe);
  align-items: center;
  border: 1px solid #e2e5ed;
  border-radius: 5px;
  h4 {
    font-family: Roboto;
    color: #3e3f42;
    font-weight: 400;
    font-size: 14px;
  }
}

.contributors_text {
  font-family: Roboto;
  color: #9ea0a5;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  text-transform: uppercase;
}

.acq-head_menu_items {
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}


.Land_documents_PDF_flex {
  display: flex;
  gap: 10px;
}
.pdfwithimagesanddesc {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* CSS */
.file-upload-label {
  display: inline-block;
  // padding: 10px 20px;
  border: 2px solid #007bff;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s, border-color 0.3s;
  margin-top: 15px;
}



.file-upload-input {
  display: none;
}

.file-upload-button {
  display: inline-block;
  margin-right: 10px;
}

.PDF_Icon_previews {
  width: 65px;
}

.property_images {
  height: 65px;
  width: 97px;
}
.nearby-card-wrapper {
  display: flex;
  flex-direction: row;
  gap: 7px;
  overflow: hidden;
}

// PDF Upload
.drop-area {
  border: 2px dashed #007BFF;
  padding: 20px;
  display: flex;
  text-align: center;
  background-color: #f4f7fa;
  margin: 20px 0;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.drop-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.drop-text {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.file-types {
  font-size: 12px;
  color: #777;
}



.file-upload-button {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.file-upload-input {
  display: none;
}


// Upload PDF
.PDF_Management_Upload {
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

}

.Land_documents_PDF_flex {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.Land_documents_PDF{
  display: flex;
  gap: 20px;
}

.pdf-card {
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 235px;
}

.pdf-thumbnail {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.pdf-details {
  padding: 10px;
  text-align: center;
}

.pdf-title {
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.pdf-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.pdf-actions .MuiIconButton-root {
  padding: 5px;
}
