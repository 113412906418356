.disposition-container {
  background-color: #fbfbfd;

  .asset-header {
    position: sticky;
    top: 74px;
    display: grid;
    width: 100%;
    grid-template-columns: 48% 1fr;
    column-gap: 40px;
    background-color: #ffffff;
    z-index: 10;
    // display: flex;
    // justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid #eaedf3;
    padding: 0 24px;
    height: 73px;
    box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);
    -webkit-box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);
    -moz-box-shadow: 5px 5px 9px rgba(0, 0, 0, 0);

    h1 {
      font-family: Roboto;
      font-weight: 500;
      font-size: 20px;
      color: #3e3f42;
      line-height: 30px;
    }

    .header-buttons {
      display: flex;
      column-gap: 20px;
      Link {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 38px;
        margin-top: 10px;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: white;
        font-size: 1rem;
        font-weight: bold;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.3s ease;
        margin: 0px 26px 19px 24px;
      }
      a {
        text-decoration: none;
        font-family: Roboto;
        font-weight: 400;
        color: #3e3f42;
        padding: 10px 20px;
        border: 1px solid #d8dce6;
        border-radius: 5px;
        opacity: 1;
        width: 221px;
        transition: all 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 38px;
        margin-top: 10px;
        padding: 10px;
        border: none;
        border-radius: 4px;
        background-color: white;
        font-size: 14px;
        font-weight: bold;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.3s ease;
        margin: 0px 2px 2px 2px;
        &:hover {
          background: var(--Primary-Purple-500, #7d55c7);
          color: white;
          transform: translateY(-2px);
        }
      }
    }
  }

  .asset-container {
    display: flex;
    width: 100%;
    column-gap: 20px;
    justify-content: center;

    .side-container {
    }

    .main-container {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 40px;
    }
  }
}

.sidecard-container {
  position: fixed;
  top: 200px;
  //   height: calc(100vh - 90px);
  height: 552px;
  background-color: #fff;
  width: 350px;
  border: 1px solid #eaedf3;
  .sidecard-header {
    padding: 25px;

    h2 {
      font-family: Roboto;
      font-weight: 500;
      font-size: 16px;
      color: #3e3f42;
    }

    border-bottom: 1px solid #eaedf3;
  }

  .sidecard-content {
    height: 381px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;
    row-gap: 20px;

    img {
      width: 330px;
      height: 140px;
      border-radius: 5px;
    }

    h4 {
      width: 90%;
      text-align: right;
      font-family: Roboto;
      font-weight: 500;
      font-size: 18px;
      color: #3e3f42;
    }

    .property-details {
      display: grid;
      grid-template-columns: 30% 1fr;
      column-gap: 20px;
      row-gap: 10px;
      text-align: left;
      width: 85%;

      h6 {
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #9ea0a5;
      }

      p {
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #3e3f42;
      }

      h4 {
        font-family: Roboto;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #3e3f42;
        margin-top: 10px;
        text-align: justify;
      }
    }
  }
}

.card-container {
  background-color: #fff;
  width: 100%;
  max-width: 730px;
  padding: 20px;
  // height: 164px;
  border-radius: 3px;
  border: 1px solid #eaedf3;

  @media screen and (max-width: 1200px) {
    max-width: 700px !important;
  }
  @media screen and (max-width: 1200px) {
    max-width: 475px !important;
  }
  @media screen and (max-width: 900px) {
    max-width: 430px !important;
  }

  .card-header {
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: space-between;
    border-bottom: 1px solid #eaedf3;

    img {
      width: 20px;
      height: 20px;
    }

    .card-heading {
      font-family: Roboto;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #9ea0a5;
    }
  }

  .card-content {
    padding: 10px 25px;

    .asset-name {
      width: 600px;
      outline: none;
      margin-top: 10px;
      border: 1px solid #e2e5ed;
      border-radius: 5px;
      resize: none;
      padding: 10px 20px !important;
    }

    .description {
      width: 100%;

      textarea {
        width: 100%;
        outline: none;
        margin-top: 10px;
        border: 1px solid #e2e5ed;
        border-radius: 5px;
        resize: none;
        padding: 10px !important;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
      }

      ::placeholder {
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        color: #9ea0a5;
        line-height: 22px;
      }
    }

    .price-input-container {
      position: relative;
      display: inline-block;
      width: 320px;
      height: 38px;

      input[type="text"] {
        padding-left: 20px;
        padding-right: 30px;
        width: 100%;
        height: 40px;
        border: 1px solid #e4e8ee;
        border-radius: 5px;
        box-sizing: border-box;
        font-size: 16px;
        text-align: left;
        outline: none;

        &::placeholder {
          color: #9ea0a5;
        }

        &:focus::placeholder {
          color: transparent;
        }
      }

      .currency-symbol {
        font-family: Roboto;
        position: absolute;
        left: 10px;
        top: 52%;
        transform: translateY(-50%);
        font-size: 14px;
        color: #9ea0a5;
        font-weight: 400;
      }
    }

    .contacts-container {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      // align-items: center;
      width: 100%;

      h2 {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal; /* 150% */
        text-transform: uppercase;
        text-align: left;
        width: fit-content;
      }

      .additional-contact-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
          margin: 0;
          flex: 1;
          text-align: left;
          width: fit-content;
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal; /* 150% */
        }

        img {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }

      .contact-button {
        display: flex;
        column-gap: 5px;
        padding: 10px 15px;
        width: fit-content;
        background-image: linear-gradient(to top, #f7f8fa, #fefefe);
        align-items: center;
        border: 1px solid #e2e5ed;
        border-radius: 5px;
        column-gap: 8px;
        margin: auto;

        img {
          width: 22px;
          height: 22px;
        }

        h4 {
          font-family: Roboto;
          color: #3e3f42;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    .website-container {
      font-family: Roboto;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #3e3f42;
      width: 100%;
    }
  }

  .photo-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;

    .media-description {
      p {
        font-size: 12px !important;
      }
    }

    .photo-card-grid {
      display: grid;
      grid-template-columns: 48% 48%;
      gap: 30px;

      .photos-card {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 20px 15px;
        border: 1px solid #eaedf3;
        border-radius: 8px;

        .img {
          border-radius: 2px;
          height: 150px;
          // width: 289px;
        }

        .controls-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          img {
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }

        .media-description {
          width: 100%;

          label {
            font-family: Roboto;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #9ea0a5;
            text-transform: uppercase;
            margin-bottom: 5px;
          }

          textarea {
            width: 100%;
            outline: none;
            border: 1px solid #e2e5ed;
            border-radius: 5px;
            resize: none;
            padding: 10px 0px;
          }

          ::placeholder {
            font-family: Roboto;
            font-weight: 400;
            font-size: 14px;
            color: #9ea0a5;
            line-height: 22px;
          }
        }
      }
    }

    h2 {
      font-family: Roboto;
      font-weight: 500;
      font-size: 12px;
      color: #9ea0a5;
      line-height: 18px;
      text-align: left;
    }

    .upload-media {
      width: 100%;
    }

    .link-input-wrapper {
      p {
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        color: #9ea0a5;
        line-height: 22px;
      }
    }
  }

  .marketing-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
  }

  .property-details-container {
    width: 100%;

    .property-tabs {
      display: flex;
      flex-direction: row;
      column-gap: 20px;
      border-bottom: 1px solid #eaedf3;

      a {
        font-family: Roboto;
        color: #6b6c6f;
        font-weight: 400;
        font-size: 16px;
        padding-bottom: 18px;

        &.active {
          border-bottom: 2px solid #7d55c7;
          transition: all 0.3;
        }
      }
    }

    .property-tab-content {
      padding: 20px 0px;
    }
  }

  .utility-details-container {
    width: 100%;

    .utility-tabs {
      display: flex;
      flex-direction: row;
      column-gap: 20px;
      border-bottom: 1px solid #eaedf3;

      a {
        font-family: Roboto;
        color: #6b6c6f;
        font-weight: 400;
        font-size: 16px;
        padding-bottom: 18px;

        &.active {
          border-bottom: 2px solid #7d55c7;
          transition: all 0.3;
        }
      }
    }

    .utility-tab-content {
      padding: 20px 0px;
    }
  }

  .manager-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    h2 {
      font-family: Roboto;
      color: #9ea0a5;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
    }

    .manager-details {
      display: flex;
      justify-content: space-between;

      .manager-profile {
        display: flex;
        column-gap: 20px;
        align-items: center;

        img {
          width: 60px;
          height: 60px;
        }

        .manager-name {
          h4 {
            font-family: Roboto;
            color: #3e3f42;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
          }

          p {
            font-family: Roboto;
            color: #9ea0a5;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
          }
        }
      }

      .manager-edit-button {
        margin: 20px 0px;
        display: flex;
        column-gap: 5px;
        padding: 10px 15px;
        width: fit-content;
        background-image: linear-gradient(to top, #f7f8fa, #fefefe);
        align-items: center;
        border: 1px solid #e2e5ed;
        border-radius: 5px;
        column-gap: 8px;

        img {
          width: 22px;
          height: 22px;
        }

        h4 {
          font-family: Roboto;
          color: #3e3f42;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    .edit-manager {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .edit-input-wrapper {
        display: grid;
        grid-template-columns: 75% 1fr;
        width: 100%;
        gap: 20px;
        align-items: center;

        input[type="text"] {
          height: 38px;
          border: 1px solid #e2e5ed;
          border-radius: 5px;
          outline: none;
          padding-left: 10px;
        }

        ::placeholder {
          font-family: Roboto;
          color: #9ea0a5;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
        }

        .btn-group {
          display: flex;
          column-gap: 20px;

          .edit-input-button {
            display: flex;
            padding: 10px 15px;
            width: fit-content;
            min-width: 66px;
            align-items: center;
            border: 1px solid #e2e5ed;
            border-radius: 5px;
            column-gap: 8px;

            h4 {
              font-family: Roboto;
              color: #3e3f42;
              font-weight: 400;
              font-size: 14px;
              margin: auto;
            }
          }
        }
      }

      p {
        font-family: Roboto;
        color: #9ea0a5;
        font-weight: 400;
        font-size: 14px;
      }
    }

    .add-collaborator-wrapper {
      display: grid;
      grid-template-columns: 60% 28% 10%;

      input[type="text"] {
        height: 38px;
        border: 1px solid #e2e5ed;
        border-radius: 5px;
        outline: none;
        padding-left: 10px;
      }

      ::placeholder {
        font-family: Roboto;
        color: #9ea0a5;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }

      .edit-input-button {
        margin-left: 20px;
        display: flex;
        padding: 10px 15px;
        width: fit-content;
        min-width: 66px;
        align-items: center;
        border: 1px solid #e2e5ed;
        border-radius: 5px;
        column-gap: 8px;

        h4 {
          font-family: Roboto;
          color: #3e3f42;
          font-weight: 400;
          font-size: 14px;
          margin: auto;
        }
      }
    }

    .add-collaborator-info {
      font-family: Roboto;
      color: #9ea0a5;
      font-weight: 400;
      font-size: 14px;
      max-width: 90% !important;
    }

    .member-permission-container {
      height: 450px;
      overflow-y: scroll;

      .member-permission-card {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        .grid-1 {
          grid-area: 1 / 1 / 2 / 5;
        }

        .grid-2 {
          grid-area: 1 / 1 / 5 / 3;
        }

        .grid-3 {
          grid-area: 3 / 1 / 5 / 5;
        }

        .grid-4 {
          grid-area: 1 / 5 / 5 / 9;
        }

        .grid-5 {
          grid-area: 5 / 1 / 6 / 9;
        }

        .grid-5:not(:last-child) .line {
          margin: 20px 0px;
        }

        .grid-5:last-child .line {
          margin-top: 20px;
        }

        .member-detail {
          display: flex;
          column-gap: 20px;
          // align-items: center;

          img {
            height: 38px;
            width: 38px;
            border-radius: 100%;
          }

          .member-name {
            h6 {
              font-family: Roboto;
              color: #3e3f42;
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
            }

            p {
              font-family: Roboto;
              color: #9ea0a5;
              font-weight: 400;
              font-size: 12px;
              line-height: 12px;
            }
          }
        }

        .member-chat {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .start-chat-button {
            margin: 20px 0px;
            display: flex;
            column-gap: 5px;
            padding: 10px 15px;
            width: fit-content;
            background-image: linear-gradient(to top, #f7f8fa, #fefefe);
            align-items: center;
            border: 1px solid #e2e5ed;
            border-radius: 5px;
            column-gap: 8px;

            img {
              width: 22px;
              height: 22px;
            }

            h4 {
              font-family: Roboto;
              color: #3e3f42;
              font-weight: 400;
              font-size: 14px;
            }
          }
        }

        .remove-wrapper {
          display: flex;
          column-gap: 8px;
          align-items: center;
          cursor: pointer;

          img {
            width: 20px;
            height: 20px;
          }

          h6 {
            font-family: Roboto;
            color: #cb333b;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
          }
        }

        .permission-options {
          display: flex;
          flex-direction: column;

          .permission-module-header {
            display: flex;
            column-gap: 10px;
            align-items: center;

            p {
              font-weight: 500;
            }
          }

          .permission-submodules {
            padding-left: 10px;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }

  .tags-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .assign-input-wrapper {
      display: grid;
      grid-template-columns: 80% 1fr;
      width: 100%;
      gap: 20px;
      align-items: center;

      input[type="text"] {
        height: 38px;
        border: 1px solid #e2e5ed;
        border-radius: 5px;
        outline: none;
        padding-left: 10px;
      }

      ::placeholder {
        font-family: Roboto;
        color: #9ea0a5;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }

      .edit-input-button {
        display: flex;
        padding: 10px 15px;
        width: fit-content;
        min-width: 66px;
        align-items: center;
        border: 1px solid #e2e5ed;
        border-radius: 5px;
        column-gap: 8px;

        h4 {
          font-family: Roboto;
          color: #3e3f42;
          font-weight: 400;
          font-size: 14px;
          margin: auto;
        }
      }
    }

    .tags-list {
      display: flex;
      column-gap: 20px;

      .tag {
        display: flex;
        column-gap: 5px;
        align-items: center;
        background-color: #fbfbfd;
        border: 1px solid #eaedf3;
        border-radius: 5px;
        padding: 5px;

        h6 {
          font-family: Roboto;
          color: #3e3f42;
          font-weight: 400;
          font-size: 12px;
        }

        button {
          font-family: sans-serif;
          font-size: 14px;
          border: none;
          outline: none;
          background-color: transparent;
          color: #bcbcbc;
          cursor: pointer;
        }
      }
    }
  }
}

.property-info-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .info-row {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    width: 100%;
    gap: 20px;

    .dropdown {
      p {
        font-family: Roboto;
        font-weight: 500;
        font-size: 12px;
        color: #9ea0a5;
        margin-bottom: 10px;
      }
    }
  }
}

.property-plan-container {
}

.property-documents-container {
}

.utility-arrangement-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .arrangement-table-header-UtilitiesArrangement {
    border-bottom: 1px solid #eaecee;
    display: grid;
    grid-template-columns: 10% 22% 22% 22% 22% 1fr;
    gap: 10px;

    h4 {
      font-family: Roboto;
      font-weight: 500;
      font-size: 12px;
      color: #9ea0a5;
      line-height: 18px;
      margin-bottom: 5px;
    }
  }

  .arrangement-table-content {
    .utility-row {
      display: grid;
      grid-template-columns: 10% 22% 22% 22% 22% 1fr;
      align-items: center;
      padding: 10px 0;
      border-bottom: 1px solid #eaecee;

      p {
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        color: #555;
        line-height: 20px;
      }

      img {
        justify-self: end;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }

  .utility-button {
    margin-top: 20px;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #ffffff;
    border: 1px solid #e2e5ed;
    border-radius: 5px;

    img {
      width: 22px;
      height: 22px;
      margin-right: 5px;
    }

    h4 {
      font-family: Roboto;
      color: #3e3f42;
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.line {
  background-color: #eaedf3;
  height: 0px;
  border: solid 1px #eaedf3;
  width: 100%;
}

.asset-tour {
  h2 {
    color: #3e3f42;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }

  .checkbox {
    padding-left: 0px !important;
  }

  .asset-tour-top {
    span {
      color: var(--LP-gray, #9ea0a5);
      font-family: Roboto;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .dates-checkbox {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 20px;
  }

  .cross-dates {
    padding-top: 30px;

    div {
      display: flex;
      align-items: center;
      gap: 16px;

      p {
        color: #6b6c6f;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
    }
  }

  .add-dates {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-left: 30px;

    .img {
      margin-top: 20px;
    }

    .cross {
      height: 24px;
      width: 24px;
    }
  }

  .add-ed {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-top: 20px;
    padding-left: 30px;

    span {
      color: #6b6c6f;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 157.143% */
    }
  }

  .chart {
    padding: 24px 0 30px 0;
  }
}

.property-documents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  .property-folders {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .folder {
    display: flex;
    align-items: center;
    gap: 12px;

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }
  }

  .documents-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px 0 0 0;
  }

  .documents-box {
    height: 351px;
    width: 174px;
    border-radius: 4px;
    border: 1px solid #eaedf3;
    padding: 12px;
    box-sizing: border-box;
    p {
      min-width: 174px;
      color: #3e3f42;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
    .documents-icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-top: 20px;
      > div {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}

.update_card_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 38px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: white;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin: 0px 26px 19px 24px;
}

.update_card_button:hover {
  background: var(--Primary-Purple-500, #7d55c7);
  color: white;
  transform: translateY(-2px);
}

.update_card_button_image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  margin-top: 10px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: white;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin: 0px 26px 19px 24px;
}
.update_card_button_image:hover {
  background: var(--Primary-Purple-500, #7d55c7);
  color: white;
  transform: translateY(-2px);
}

.file-input-wrapper {
  display: flex;

  align-items: center;
  padding: 20px;
}

.file-input-label {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.file-input-label-remove {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
}

.file-input-label:hover {
  background-color: #0056b3;
}

.start-syndicate-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
}

.start-syndicate-button.loading {
  background-color: #007bff; /* Keep background color the same when loading */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #0000ff; /* Change the color to blue */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
  margin: 0; /* Remove margin to center the spinner */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #0000ff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Spinner

.Card_Assetvalue {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.assets_images {
  width: 300px;
  height: 200px;
}

.update_card_button_pdf {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  margin-top: 10px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: white;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.update_card_button_pdf:hover {
  background: var(--Primary-Purple-500, #7d55c7);
  color: white;
  transform: translateY(-2px);
}

.pdf_file_upload {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

// .file_input_upload_floor{
//   display: inline-block;
//   background-color: indigo;
//   color: white;
//   padding: 0.5rem;
//   font-family: sans-serif;
//   border-radius: 0.3rem;
//   cursor: pointer;
//   margin-top: 1rem;
// }

.preview-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.disposition-container {
  .asset-container {
    .main-container {
      padding-bottom: 30px;
      .card-container {
        max-width: 100%;
        fill: #fff;
        stroke-width: 1px;
        stroke: #eaedf3;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));
        padding: 0 !important;

        .card-header {
          padding: 24px 29px !important;
          justify-content: flex-start !important;
          margin: 0 !important;
          line-height: normal !important;

          .card-heading {
            line-height: normal !important;
          }
        }
        .card-content {
          padding: 25px 30px !important;

          > div {
            width: 100% !important;
          }
          .asset-name {
            width: 100% !important;
            margin: 0 !important;
          }
          input,
          .price-input-container {
            width: 100% !important;
          }

          .pdf_file_upload {
            .file-input-wrapper {
              padding: 20px 0 !important;
            }
          }

          .property-info-container {
            .info-row {
              .dropDownOuterCon {
                position: static;
                margin: 0 !important;
                z-index: 1 !important;
                opacity: 1 !important;
                border: 0 !important;
                /* right: 0; */
                /* top: 100%; */
                /* background-color: white; */
                /* border: 1px solid #eaedf3; */
                /* outline: none; */
                /* opacity: 0; */
                /* z-index: -1; */
                /* max-height: 0; */
                /* transition: opacity 0.2s ease, max-height 0.2s ease, z-index 0.2s;*/

                p {
                  margin-bottom: 7px !important;
                }
                .dropDownMAIN {
                }
              }
            }
          }

          .utility-details-container {
            .checkbox {
              padding: 0 !important;
            }
          }
        }
        .card-footer {
          padding: 20px 30px !important;
          padding-top: 0px !important;
          margin: 0px !important;

          button {
            margin: 0 !important;
          }
        }
      }
    }
  }
  .side-container {
    position: relative;
    .sidecard-container {
      position: sticky !important;
      top: 163px !important;
      height: fit-content !important;

      .sidecard-content {
        height: fit-content !important;
      }
    }
  }
}
