.notifications {
    border: 1px solid #EAEDF3;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.04));

    .receive {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 20px;
        border-bottom: 1px solid #EAEDF3;
        >div{
            display: flex;
            gap: 4px;
            >.dropDownOuterCon{
                width: 80px;
                margin-top: 0px !important;
            }
        }

        p {
            color: #6B6C6F;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px
        }
    }

    .notifications_box {
        padding: 20px;
        border-bottom: 1px solid #EAEDF3;
        p {
            color: #3E3F42;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }
    }

    .row_wrapper {

        .row {
            display: grid;
            grid-template-columns: 25% 17% 17% 25% 16%;
            padding: 20px 0;
            border-bottom: 1px solid #EAEDF3;

            >div {
                display: flex;
                align-items: center;
                gap: 20px;

                img {
                    width: 16px;
                    height: 16px;
                    object-fit: contain;
                }
            }
        }
    }

    .schedule {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        width: 369px;
        // margin: auto;
        padding-top: 20px;

        p {
            color: #9EA0A5;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
        }
    }

    .days_wrapper {
        display: flex;
        align-items: center;
        gap: 12px;
  
        .days {
            cursor: pointer;
            display: flex;
            width: 38px;
            height: 38px;
            justify-content: center;
            align-items: center;
            background: linear-gradient(#F6F7F9, #FFFFFF);
            border-radius: 100%;
            border: 1px solid #D8DCE6;
            text-transform: capitalize;
            color: #3E3F42;
            font-size: 14px;
            
        }
        .days.active {
            background: linear-gradient(#7D55C7, #FFFFFF);
            color: #FFFFFF;
            border: 1px solid #7D55C7;
        }

    }

    .timer {
        display: flex;
        align-items: center;
        gap: 20px;

        .selected {
            color: var(--purple-pantone-2665-c, #7D55C7);
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            text-transform: uppercase;
        }

        input {
        }

        .dropDownOuterCon {
            margin-top: 0px !important;
            width: 80px;


        }
    }
    .promotions{
        color: #3E3F42;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px; 
    }
    .promotions_row{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
    }
}
.dropdown{
    background-color: #7D55C7;
}