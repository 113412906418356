.checkbox {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-transform: capitalize;
  user-select: none;
  display: flex;
  align-items: center;
  gap: 10px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #f6f7f9;
    border: 1px solid #e9e9ea;
    border-radius: 4px;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid #ffffff;
      border-width: 0px 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  &:hover input ~ .checkmark {
  }

  input:checked ~ .checkmark {
    background-color: #7d55c7 !important;
    border: 1px solid #7d55c7; // Border color when checked
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark_2 {
    margin-top: 20px;
  }
  .variant_2 {
    display: flex;
    align-items: center;
    gap: 20px;

    img {
      width: 56px;
      height: 56px;
      margin-left: 10px;
    }

    p {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    span {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  }
}
