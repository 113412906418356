.deals {
  display: flex;
}

.deals-left-inner {
  width: 100%;
  height: 100% !important;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

@media screen and (max-width: 950px) {
  .deals-left-inner {
    width: 230px !important;
  }
}

.deals-left {
  display: flex;
  flex-direction: column;
}

@media (max-width: 900px) {
  .deals-left {
    display: none;
  }

  .deals-left.open {
    display: flex;
  }
}

.hamburger-menu {
  display: none;
  cursor: pointer;

  @media (max-width: 900px) {
    display: block;
  }
}

.hamburger-menu-lines {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
}

/* Other CSS rules... */

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  // background: #ff0000;
  width: 0;
  background: transparent;
}

.deals-center {
  width: 80% !important;
  height: max-content;
  flex-shrink: 0;

  @media screen and (max-width: 1440px) {
    width: 80% !important;
  }
  @media screen and (max-width: 1000px) {
    width: 72% !important;
  }
  @media screen and (max-width: 950px) {
    width: 64% !important;
  }
}

.deals-left-heading {
  display: block;
  padding: 25px 0 15px 30px;
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-transform: uppercase;
}

.deals-left-menu {
  cursor: pointer;
  box-sizing: border-box;
  // width: 270px;
  height: 46px;
  flex-shrink: 0;
  padding: 13px 0 13px 30px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.deals-menu-active {
  background: rgba(125, 85, 199, 0.05);
  box-shadow: 3px 0px 0px 0px #6758f3 inset;
  color: var(--purple-pantone-2665-c, #7d55c7);
}

.left-menu-title {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 157.143% */
}

.analytics {
  padding-top: 18px;

  padding-left: 30px;
  height: 238px;
  width: 210px;
}

.ana-name {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-transform: uppercase;
}

.ana-main {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 19px 16px 20px 0;
}

.ana-main-right > span:first-child {
  display: block;
  color: #3e3f42;
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  /* 146.154% */
}

.ana-main-right > span:last-child {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-transform: uppercase;
}

.ana-bottom {
  width: 210px;
  height: 38px;
  flex-shrink: 0;
  border: 2px solid #e2e5ed;
  border-radius: 3.5px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 950px) {
    width: 165px !important;
    margin: auto !important;
  }
}

.ana-bottom > span {
  color: #3e3f42;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.activity {
  // padding-top: 18px;
  width: 270px;
  // height: 88px;

  padding-left: 30px;
}

.act-name {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-transform: uppercase;
  display: block;
  margin-bottom: 11px;
}

.act-main {
  margin-bottom: 11px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding-right: 30px;
}

.act-left > img {
  border-radius: 50%;
}

.act-right-text {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.act-text {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.act-date {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.act-right-first {
  width: 166px;
}

//-----------------center section-----------------

.deals-upper {
  border-bottom: 1px solid #eaedf3;
}

.deals-upper-inner {
  width: 92%;
  padding: 10px 0;
  margin: 0 auto;
  // width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.deals-upper-name {
  padding-right: 30px;
  border-right: 1px solid #eaedf3;
}

.deals-upper-name > span {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.deals-u-left {
  display: flex;
  gap: 20px;
}

.deals-c-upper-left > ul {
  display: flex;
  gap: 30px;
}

.deals-c-upper-left > ul > li {
  cursor: pointer;
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}

.active {
  color: var(--Primary-Purple-500, #7d55c7);
}

.deals-u-right {
  display: flex;
  align-items: center;
  gap: 20px;
  float: right;
}

.deals-u-right > div {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.add-deals-btn {
  padding: 9px 14px 9px 12px;
  background-color: #7d55c7;
  border-radius: 5px;
}

.add-deals-btn > span {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

// ----------------deals filter-----------------
// ---------------------------------------------
.filter-wrapper-main {
  width: 285px;
  height: 1080px;
  overflow: scroll;
  flex-shrink: 0;
  background: #fff;
  box-shadow: -1px 0px 0px 0px #ececee;
  position: absolute;
  top: 135px;
  right: 5px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;

  .checkbox {
    padding-left: 0px !important;
    font-size: 16px;
  }

  .filter-header {
    padding: 22px 20px 0 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    // gap: 50px;
    > span {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      /* 150% */
      text-transform: uppercase;
    }

    > div {
      display: flex;
      gap: 10px;
      align-items: center;

      > span {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
    }
  }

  .filter-main {
    padding: 0 30px 0 30px;

    .filter-card {
      padding-bottom: 24px;

      .filter-c-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          /* 150% */
        }

        margin-bottom: 14px;
      }

      .filter-c-list {
        display: flex;
        justify-content: space-between;
        align-items: center;

        // margin-bottom: 8px;
        > span {
          color: #6b6c6f;
          text-align: right;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }
      }

      .auther-list {
        margin-top: 3px;
        display: inline-flex;
        padding: 4px 12px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        border: 1px solid #eaedf3;
        background: #fbfbfd;

        > span {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 157.143% */
        }
      }

      .auther-list-w {
        fill: #fff;
        stroke-width: 1px;
        stroke: #e2e5ed;
        box-shadow: 0px 1px 2px 0px rgba(102, 113, 123, 0.21) inset;
        padding: 4px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
      }
    }
  }
}

// ----------------add deals filter-----------------
// ---------------------------------------------

.add-deal-filter-wrapper-main {
  width: 200px;
  position: absolute;
  top: 135px;
  right: 25px;

  border-radius: 4px;
  border: 1px solid #d8dce6;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);

  > .divider {
    border: 1px solid #9ea0a5;
    margin: 0;
    padding: 0;
  }

  a {
    > div {
      padding: 9px 10px;
      display: flex;
      gap: 12px;
      cursor: pointer;

      > span {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
      }
    }
  }
}

// ----------------deal card-----------------
// ---------------------------------------------
.deal-card-wrapper {
  width: 100%;
  padding: 30px !important;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  @media screen and (max-width: 1440px) {
    justify-content: flex-start;
  }

  .dealcard-success {
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      color: var(--green-pantone-355-c, #009639);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal; /* 183.333% */
    }
  }
}

.deal-card {
  width: 100%;
  max-width: 350px;
  height: 100%;
  flex-shrink: 0;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  overflow: hidden;
}

.deal-card-upper {
  position: relative;

  img {
    width: 100%;
    height: 200px;
  }
}

.deal-c-u-t {
  position: absolute;
  bottom: 25px;
  left: 16px;
}

.deal-c-u-t > span:first-child {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  display: block;
}

.deal-c-u-t > span:last-child {
  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.dot {
  position: absolute;
  top: 16px;
  right: 16px;
  img {
    height: fit-content;
  }
}

.deal-card-c-inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 24px;

  &:first-child {
    padding-top: 16px;
    padding-bottom: 12px;
  }
  &:nth-child(2) {
    padding-bottom: 20px;
  }
}

.deal-card-list > span:first-child {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  display: block;
}

.deal-card-list > span:last-child {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.divider {
  width: 100%;
  height: 1px;
  background: #eaedf3;
  margin-bottom: 16px;
}

.deal-card-progress {
  padding: 0 24px 0 24px;
}

.card-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-progress > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card-progress > div:first-child > span {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
  text-transform: uppercase;
}

.card-progress > div:last-child > span {
  color: #6b6c6f;
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.bar-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;

  span {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; /* 133.333% */
    text-transform: uppercase;
  }
}

.bar-wrapper > img {
  width: 66px;
  height: 8px;
}

.bar-container {
  display: flex;
  gap: 13.23px;
  margin-top: 12px;
}

.deal-card-bottom {
  width: 350px;
  height: 72px;
  flex-shrink: 0;
  background: #f5f6f9;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 24px;

  @media screen and (max-width: 1440px) {
    width: 100% !important;
  }
}

.deal-card-bottom > div:first-child {
  display: flex;
  align-items: center;
  gap: 19px;
}

.deal-card-bottom > div:first-child > div > img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.deal-card-bottom > div:last-child {
  width: 73px;
}

.deal-card-bottom > div:last-child > span {
  color: #9ea0a5;
  text-align: right;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
}

.dealcard-sucess {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dealcard-sucess > span {
  color: var(--LP-mid-gray, #6b6c6f);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 183.333% */
}

.dealcard-s-name {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */

  @media screen and (max-width: 1440px) {
    span {
      font-size: 14px !important;
    }
  }
}

.notification-bell {
  position: relative;
  float: right;
}



.bell-icon .fa-bell {
  font-size: 24px;
  width: 28px;
  margin-top: 5px;
}

.bell-icon .new-notifications {
  color: red;
}

.bell-icon .badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}

// -------------------------------------------------------






.notification-item.read {
  background-color: #f8f8f8;
}

.notification-item.unread {
  background-color: #fff;
  font-weight: bold;
}

.notification-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
}

.notification-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.notification-content {
  flex: 1;
}

.notification-message {
  margin-bottom: 8px;
  color: #3e3f42;
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.notification-timestamp {
  font-size: 12px;
  color: #666;
}



.analyticsDetails_container {
  .analyticsDetails_wrapper {
    .analyticsDetails_header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 23px 30px;
      max-height: 74px;
      border-bottom: 1px solid #eaedf3;
      h2 {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
      }
      button {
        border: 0;
        background: none;
      }
    }

    .dealReport_wrapper {
      padding: 32px 30px;
      .dealReport_box {
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-radius: 4px;
        border: 1px solid #eaedf3;
        background: #fff;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
        .dealReport_header {
          display: grid;
          grid-template-columns: 12% 78%;
          align-items: center;
          max-height: 74px;
          border-bottom: 1px solid #eaedf3;
          padding: 14px 30px;

          h2 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 155.556% */
          }

          .nav_box {
            .deals-c-upper-left {
              > ul {
                justify-content: center;

                li {
                  padding: 12px 8px;

                  &.active {
                    border-radius: 4px;
                    border: 1px solid #ebe7ef;
                    background: #faf9fb;
                    box-shadow: 0px 1px 1px 0px rgba(99, 115, 129, 0.61) inset, 0px 1px 4px 0px rgba(99, 115, 129, 0.2) inset;
                  }
                }
              }
            }
          }
        }
        .dealReport_graph_wrapper {
          display: grid;
          grid-template-columns: 1fr 2fr 1fr;
          padding: 39px 0;

          .deal_count_box,
          .budgetGraph_box {
            border-right: 1px solid #eaedf3;
          }

          .budgetGraph_box {
            padding: 0 37px;

            .budget_chart_container {
              .barchart_nav {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                h2 {
                  color: var(--LP-black, #3e3f42);
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }
                button {
                  border: 0;
                  background: none;
                }
              }
            }
          }

          .deal_count_box {
            .dealCountChart_container {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              .chart_details {
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;

                .dealCount_caption {
                  color: #9ea0a5;
                  text-align: right;
                  font-family: Roboto;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 18px; /* 150% */
                  text-transform: uppercase;
                }
                .deal_count {
                  color: #3e3f42;
                  text-align: right;
                  font-family: Roboto;
                  font-size: 48px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 60px; /* 125% */
                }
              }
            }
          }

          .dealStatus_box {
            padding: 0 50px;

            h4 {
              color: var(--LP-black, #3e3f42);
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              margin-bottom: 14px;
            }

            .ant-checkbox-group {
              display: flex;
              flex-direction: column;
              gap: 8px;

              .ant-checkbox-wrapper {
                gap: 6px;
              }

              span {
                color: #6b6c6f;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
              }
            }
          }
        }
      }
      .deal_progress_box {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 17px;
      }
    }
  }
}

.LeftMenuchart_container {
  padding: 0 30px;
  margin-top: 50px;
  .leftMenuHeader {
    color: #9ea0a5;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    text-transform: uppercase;
  }
  .topSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .chart_details {
    .deal_count {
      color: #3e3f42;
      font-family: Roboto;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 38px; /* 146.154% */
    }
    .dealCount_caption {
      color: #9ea0a5;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
      text-transform: uppercase;
    }
  }
}

.left_chart_wrapper {
  display: flex;
  flex-direction: column;
}
.open_analytics_btn {
  border: 0;
  background: none;
  border: 1px solid #e2e5ed;
  padding: 8px 35px;
  height: 38px;
  margin: 0 auto;
  color: #3e3f42;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  text-wrap: nowrap;
  margin-top: 20px;
}
.dealReport_table_box {
  padding: 0 30px;
  background: #fff;

  .asset_info_container {
    display: flex;
    align-items: center;
    gap: 20px;

    img {
      width: 38px;
      height: 38px;
    }

    .asset_info {
      display: flex;
      flex-direction: column;
      gap: 6px;
      align-items: flex-start;

      .asset_name {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .asset_addrs {
        color: #9ea0a5;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-content {
        .ant-table-thead {
          height: 43px;
          background: #fff;
          tr {
            th {
              color: #9ea0a5;
              background: #fff;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px; /* 150% */
              text-transform: uppercase;
              border-top: 1px solid #eaedf3;
            }
          }
        }

        .ant-table-tbody {
          tr {
            td {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px; /* 157.143% */
            }
          }
        }
      }
    }
  }
}

.dealPage_container {
  .deals {
    .deals-left {
      width: 100%;
      max-width: 260px;
      position: fixed;

      .deals-left-inner {
        padding-bottom: 30px;
        min-height: 100vh;
      }
    }
    .deals-center {
      width: 100vw;
      max-width: 80vw;
      margin-left: 272px;

      .deals_content_container {
        margin-top: 70px;

        @media only screen and (max-width: 1290px) {
          margin-top: 115px;
        }
      }

      .deals-upper {
        height: 67px;
        background: #fff;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.04);
        display: flex;
        align-items: center;
        position: fixed !important;
        top: 74px !important;
        z-index: 100;
        width: 100%;
        max-width: 78vw !important;

        @media only screen and (min-width: 1600px) {
          max-width: 1329px !important;
        }

        .deals-upper-inner {
          width: 100%;
          padding: 0 20px;
          height: 100%;
          .deals-u-left {
            height: 100%;
            align-items: center;
            gap: 10px;
            .deals-c-upper-left {
              height: 100%;
              align-items: center;
              ul {
                height: 100%;
                align-items: center;
                gap: 15px;
                li {
                  height: 100%;
                  display: flex;
                  align-items: center;
                  transition: all 0.2s ease;
                  border-bottom: 3px solid transparent;
                  color: #6b6c6f;
                  font-family: Roboto;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px; /* 171.429% */
                  text-transform: capitalize;
                  &:hover {
                    color: #3e3f42 !important;
                    border-bottom: 3px solid var(--blue-pantone-2935-c-100, rgba(125, 85, 199, 0.71));
                    transition: all 0.2s ease;
                  }

                  &.active {
                    color: #3e3f42 !important;
                    border-bottom: 3px solid var(--blue-pantone-2935-c-100, rgba(125, 85, 199, 0.71));
                    transition: all 0.2s ease;
                  }
                }
              }
            }
          }
        }
      }

      .deals-u-right {
        gap: 10px !important;

        .optional_btns {
          height: 38px;
        }

        .commercial_btn,
        .year_btn,
        .sort_btn {
          border: 1px solid #d8dce6;
          padding: 8px 16px;
          border-radius: 8px;

          display: flex;
          align-items: center;
        }
      }
    }
  }
}
.analytics_modal {
  position: absolute;
  width: 100vw;
  left: 0;
  top: 100vh;
  opacity: 0;
  // visibility: hidden;
  display: none;
  transition: top 0.3s ease, opacity 0.3s ease;
  z-index: 111;
  background: #fff;

  &.openAnalytics {
    top: 75px;
    opacity: 1;
    // visibility: visible;
    display: block;
    transition: top 0.3s ease, opacity 0.3s ease;
  }

  &.closeAnalytics {
    top: 100vh;
    opacity: 0;
    transition: top 0.3s ease, opacity 0.3s ease;
  }

  .analyticsDetails_container {
    .analyticsDetails_wrapper {
      .analyticsDetails_header {
        position: fixed;
        background: #ffffff;
        z-index: 111;
      }

      .dealReport_wrapper {
        .dealReport_box {
          margin-top: 67px;
        }
      }
    }
  }
}

.acquitionDealCard_wrapper {
  .acquitionDealCard_info {
    cursor: pointer;
    .acquitionDealCard_center {
      padding: 16px 24px;
      padding-bottom: 0;
      .upperSection {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #eaedf3;

        .priceInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h4 {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal; /* 157.143% */
          }

          .status {
            display: flex;
            align-items: center;
            gap: 5px;
            color: #6b6c6f;
            text-align: right;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal; /* 150% */
            .statusIndicator {
              width: 14px;
              height: 14px;
              border-radius: 50%;
              background: #ffc40063;
              display: flex;
              align-items: center;
              justify-content: center;
              .innerCircle {
                background: #ffc400;
                width: 8px;
                height: 8px;
                border-radius: 50%;
              }
            }
          }
        }
        .propertyInfo {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          .propBox {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;

            .proplabel {
              color: #9ea0a5;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal; /* 150% */
            }

            .propvalue {
              color: #3e3f42;
              font-family: Roboto;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal; /* 150% */
            }

            &:last-child {
              align-items: flex-end;
            }
          }
        }
      }
    }
    .acquitionDealCard_bottom {
      .importerInfo {
        padding: 20px 25px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 10px;
        .importerPic {
          width: 38px;
          height: 38px;
          object-fit: cover;
          border-radius: 50%;
        }

        .importerDetail {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .topSection {
            display: flex;
            gap: 8px;
            align-items: center;

            p,
            span {
              color: var(--green-pantone-355-c, #009639);
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal; /* 183.333% */
            }
          }
          .bottomSection {
            color: #3e3f42;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal; /* 157.143% */
          }
        }
      }
    }
  }
  .acquitionDealCard_btnBox {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to top, #f7f8fa, #fefefe);
    border: 1px solid #e2e5ed;
    padding: 15px;
    button {
      background: none;
      outline: 0;
      border: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.deal-card-center{
  cursor: pointer;
}


// ------------------------------------Notification

/* Notifications Dropdown Container */
.notifications-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  font-family: Arial, sans-serif;
}

/* Notifications Header */
.notifications-header h3 {
  margin: 0;
  font-size: 1.1em;
  font-weight: bold;
  color: #333;
  padding: 10px 0;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

/* No Notifications Message */
.no-notifications p {
  color: #999;
  font-size: 0.9em;
  text-align: center;
  padding: 10px 0;
}

/* Notifications List */
.notifications-list {
  max-height: 300px;
  overflow-y: auto;
  padding: 10px 0;
}

/* Notification Item */
.notification-item {
  display: flex;
  // align-items: center;
  padding: 10px;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.notification-item:hover {
  background-color: #e9e9e9;
}

/* Read and Unread States */
.notification-item.unread {
  background-color: #e3f2fd;
}

/* Notification Image */
.notification-image img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Notification Content */
.notification-content {
  display: flex;
  flex-direction: column;
}

.notification-message {
  font-size: 0.9em;
  color: #333;
}

.notification-timestamp {
  font-size: 0.8em;
  color: #666;
}
