.History_main_container {
  background: #f9f9f9;
  border-radius: 8px;
}

.Predicted_data_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  border-radius: 10px;
}

.Predicted_data_container h2 {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}

.Bugget_data_History {
  display: flex;
  align-items: center;
  gap: 15px;
}

.predicted-data-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
}

.left-section {
  width: 65%;
}

.combined-card {
  padding: 25px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #fff;
}

.section.no-action h3,
.section.needs-change h3,
.section.suggestions h3 {
  font-size: 18px;
  font-weight: bold;
  background: #f2eef9;
  border-radius: 8px;
  padding: 10px;
  margin: 0;
}

.card_infomation_withimage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.card_infomation_withimage h3 {
  margin-right: 15px;
}

.card_infomation_withimage img {
  width: 24px;
  height: 24px;
}

.paragraphtext_forBuggetting {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #9ea0a5;
}

.divider {
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 15px 0;
}

.invoice-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.invoice-chart {
  width: 50%;
  height: 250px;
}

.invoice-details h4 {
  margin: 0;
  font-size: 24px;
}

.profit-growth {
  margin-top: 20px;
}

.profit-growth p {
  margin: 5px 0;
}

// chatrightside

.right-section {
  width: 30%;
  padding: 10px;
  background: white;
  border-radius: 10px;
  height: fit-content;
  border: 1px solid #e0e0e0;
}

.ai-chat-header {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 10px 0 0;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  justify-content: space-between;
}

.ai-chat-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.user-avatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  border-radius: 50%;
}

.message {
  display: flex;
  align-items: flex-end;
  padding: 12px;
  margin: 5px 0;
  border-radius: 10px;
  font-size: 14px;
  line-height: 1.4;
}

.ai-message {
  justify-content: flex-start;
}

.chat-text-container {
  padding: 8px;
  border-radius: 10px 10px 10px 0px;
  max-width: 100%;
  word-wrap: break-word;
  background-color: inherit;
  color: inherit;
  background: #f2f2f2;
  p {
    padding: 4px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #3e3f42;
  }
}
.chat-text-container-user {
  padding: 8px;
  border-radius: 10px 10px 0px 10px;
  max-width: 100%;
  word-wrap: break-word;
  background-color: inherit;
  color: inherit;
  background: #f2f2f2;
  p {
    padding: 4px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #3e3f42;
  }
}

.chat-time {
  display: block;
  text-align: start;
  font-size: 10px;
  color: #9e9e9e;
  margin-top: 5px;
  position: relative;
  left: 60px;
}
// .chat-time-user {
//   display: block;
//   text-align: right;
//   font-size: 10px;
//   color: #9e9e9e;
//   margin-top: 5px;
//   position: relative;
//   right: 70px;
// }

.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  gap: 10px;
}

.chat-input input {
  width: 85%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  font-size: 14px;
}

.ai-chat-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.chat-messages {
     /* height: 350px; */
     overflow: auto;
     /* border-radius: 8px; */
     display: flex;
     flex-direction: column;
     justify-content: flex-start; 
     /* background: red; */
     height: 300px !important;
}

.user-message {
  margin-left: auto;
  display: flex;
  gap: 10px;
  float: right;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
}

.chat-input input {
  width: 85%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  font-size: 14px;
}

.BeartacticAI_History {
  padding: 10px;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: white;
  border-radius: 4px;
  border-radius: 4px;
  border: 1px solid rgba(125, 85, 199, 0);
  background: var(--Primary-Purple-500, #7d55c7);
  box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset, 0px 1px 1px 0px rgba(19, 31, 21, 0.1);
}

.invoice-details-for-BeartacticAI {
  text-align: center;
  padding-top: 20px;
}

.invoice-details-for-BeartacticAI div:first-child {
  margin-bottom: 10px;
}

.invoice-details-for-BeartacticAI h4 {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 400;
  text-align: right;
  color: #3e3f42;
}

.invoice-details-for-BeartacticAI p:first-of-type {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;  
  color: #9EA0A5;
}




.invoice-details-for-BeartacticAI p:nth-child(2) {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: right;
  color: #9EA0A5;
  
}

.invoice-details-for-BeartacticAI p:nth-child(1)::before {
  content: "•";
  color: #1976d2;
  font-size: 16px;
  margin-right: 5px;
}



.history-main-container {
  padding: 20px;
}

.profit-growth-section {
  margin-top: 30px;
}

.profit-growth-section h4 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 20px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
}

.growth-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.growth-chart {
  width: 60%;
}

.growth-data {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 35%;
}

.growth-percentage {
  font-family: Roboto;
  font-size: 64px;
  font-weight: 300;
  line-height: 80px;
  color: #34aa44;
}

.growth-text {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #9ea0a5;
}

.ai-chat-header_for_BeartacticAI {
  display: flex;
  align-items: center;
  gap: 5px;
}
.maximize-btn {
  background-color: #f0f0f0; 
  border: none; 
  border-radius: 4px; 
  width: 30px;
  height: 30px;
  display: flex; 
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); 
  transition: background-color 0.3s ease, box-shadow 0.3s ease; 
  font-size: 16px; 
  color: #333; 
}

.maximize-btn:hover {
  background-color: #e4e4e4; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.maximize-btn:active {
  background-color: #d4d4d4; 
}
.amount_tobepaid {
  display: flex;
  justify-content: flex-end; /* Aligns both p and span to the right */
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

.amount_tobepaid p, 
.amount_tobepaid span {
  margin: 0;
}
