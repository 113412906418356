.Stakeholders-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-container {
  display: flex;
  align-items: center;
}

.search-input {
  padding: 10px;
  border: none;
  outline: none;
  font-size: 17px;
  border: 1px solid grey;
  width: calc(100% - 40px); 
}

.search-button,
.normal-button,
.dropdown-button {
  padding: 10px;
  font-size: 17px;
  border: 1px solid grey;
  cursor: pointer;
  background-color: transparent;
}

.search-button:hover,
.normal-button:hover,
.dropdown-button:hover {
  background-color: lightgrey; 
}

.search-button:focus,
.normal-button:focus,
.dropdown-button:focus {
  outline: none; 
}

.search-button:hover svg,
.normal-button:hover svg,
.dropdown-button:hover svg {
  color: white;
}

.search-button svg,
.normal-button svg,
.dropdown-button svg {
  color: grey; /* Default icon color */
}

.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.button-container {
  display: flex;
  gap: 20px;
}

// Dropdown

.dropdown-data {
  display: flex;
  gap: 20px;
  border-bottom: none;
}

.dropdown-data select {
  border: none;
  color: var(--primary-gray-100-text, #9ea0a5);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  text-transform: uppercase;
}

.hrtag {
  margin-top: 10px;
}

// Stack Holder

.userdata-header {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  gap: 200px;
  margin-left: 60px;
  align-items: center;
  p {
    color: var(--Black, #3e3f42);
    leading-trim: both;
    text-edge: cap;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
    text-transform: uppercase;
  }
}


.StackHolder-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.StackHolder-details-profile {
  display: flex;
  align-items: center;
}

.StackHolder-details-profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.StackHolder-details p {
  color: #3e3f42;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  margin: 0;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.circular-loader {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.Equityplans-data {
  text-align: center;
  padding: 20px;
}

.Equityplans-Error-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Equityplans-data-container-image img {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.StackHolder-details:hover {
  border: 1px solid #7d55c7;
  // box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.Stakeholders-Manage {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  width: 150px;
  color: white;
  border-radius: 4px;
  border-radius: 4px;
  border: 1px solid rgba(125, 85, 199, 0);
  background: var(--Primary-Purple-500, #7d55c7);
  box-shadow: 0px 2px 0px 0px rgba(255, 255, 255, 0.06) inset, 0px 1px 1px 0px rgba(19, 31, 21, 0.1);
}
.userdata p {
  color: var(--Black, #3e3f42);
  leading-trim: both;
  text-edge: cap;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  text-transform: uppercase;
}

.Stakeholders-Download {
  padding: 0;
  margin: 0;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: none;
  color: black;
  background-color: white;
  border-radius: 4px;
  border: 1px solid var(--Primary-Purple-50, #eaedf3);
  background: #fff;
}

// POPup Menu
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.popup {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.popup-content {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  label {
    color: var(--Black, #3e3f42);
    leading-trim: both;
    text-edge: cap;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
  }
}

.form-field {
  margin-bottom: 15px;
}

.form-field label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-field input[type="text"],
.form-field input[type="email"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  border: none;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.cancel-button {
  margin-right: 10px;
  background-color: #ccc;
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.add-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
}

/* Stakeholders.scss */

.lodingscreen-data {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 50vh;
}
.circular-loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7d55c7;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  position: relative;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}



// 

