.off-market {
  width: 730px;
  height: 896px;
  flex-shrink: 0;
  box-sizing: border-box;
  margin-top: 35px
}

.off-market > .heading {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
  margin-bottom: 24px;
}

.off-compo {
  width: 226px;
  height: 392px;
  margin-bottom: 50px;
}

.off-compo > .heading {
  margin-top: 20px;
  color: #3e3f42;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin-bottom: 5px;
}

.disc {
  width: 100%;
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.bottom-icons {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
.bottom-icons > div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.bottom-icons > div > div {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.text {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

#line {
  margin-top: 18px;
}

.last-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
}

.last-left {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}

.last-right {
  display: flex;
  width: 96px;
  height: 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #7d55c7;
  color: white;
  border: none;
  border-radius: 5px;
}

.text-left {
  position: relative;
  left: -10px;
}

.text-right {
  position: relative;
  left: 10px;
}

.inner-off {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 28px;
}

.pending {
  width: 255px;
  height: 374px;
  flex-shrink: 0;
  margin-top: 30px;
  border: 1px solid #e2e5ed;
  padding: 10px;
}

.pending h3 {
  color: #9ea0a5;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

.pending-compo {
  display: flex;
  justify-content: space-between;
}

.pending-compo .inner-pending-compo {
  display: flex;
}

.inner-pending-compo div {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  margin-left: 20px;
}

.right-div {
  color: #3e3f42;
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.pending-inner {
  display: flex;
  flex-direction: column;
}

.property_images{
  border-radius: 5px;
}