.notfound-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  // background-image: url('real-estate-placeholder.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
}

.notfound-content {
  background: rgba(255, 255, 255, 0.8);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.notfound-title {
  font-size: 72px;
  margin: 20px 0;
  color: #333;
}

.notfound-message {
  font-size: 20px;
  margin: 20px 0;
  color: #555;
}

.notfound-link {
  display: inline-block;
  margin-top: 20px;
  padding: 12px 24px;
  background-color: #0066cc;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.notfound-link:hover {
  background-color: #003366;
}
