.kanban-calendar-wrapper {
  position: relative;
}

.fc-event {
  pointer-events: none;
}

.fc-theme-standard td {
  height: 167px;
  width: 118px;
}

.close-button {
  color: #ffffff;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
  background: none;
  border: none;
  padding: 12px;
  outline: none;

  img {
    height: 30px;
    width: 30px;
  }
}

.fc .fc-col-header-cell-cushion {
  color: #9ea0a5;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
}

.fc-theme-standard .fc-scrollgrid {
  border: none !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid #eaedf3 !important;
}

.fc .fc-daygrid-day-number {
  color: #3e3f42;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding-right: 10px;
}

.fc .fc-daygrid-day.fc-day-today {
  background: none !important;
  .fc-daygrid-day-bg {
    // height: 86px !important;
    margin-top: 0px;
    background: none;
    // box-shadow: 3px 0px 0px 0px #1665D8 inset;
  }
}

// .fc-direction-ltr .fc-daygrid-event.fc-event-end,
// .fc-direction-rtl .fc-daygrid-event.fc-event-start {
//     color: #F5A623 !important;
//     visibility: visible !important;
//     background: rgba(245, 166, 35, 0.10);
//     // box-shadow: 3px 0px 0px 0px #F5A623 inset;
//     border: none;
//     height: 86px;
// }

.fc-daygrid-event-harness {
  margin-top: 30px !important;
}

.fc-toolbar-title {
  font-size: 16px !important;
  height: 38px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #eaedf3;
  border-bottom: 1px solid #eaedf3;
  text-wrap: nowrap;
  width: 160px;
}

.fc-prev-button {
  background: #ffffff;
  color: #9ea0a5;
  border: 1px solid #eaedf3;
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc .fc-button {
  background: #ffffff !important;
  color: #9ea0a5 !important;
  border: 1px solid #eaedf3 !important;
  outline: none !important;
  height: 38px !important;
  width: 38px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc .fc-toolbar.fc-header-toolbar {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: -57px;
  left: 373px;
}

.event_wrapper {
  padding: 5px;
  border-radius: 3px;
  border-radius: 3px;
  height: 94px;
  color: #ff902a;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.completed {
  padding: 20px;
}

.not-completed {
  padding: 20px;
}

.task_data_font {
//   color: #34aa44;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  margin-left: 9px;
}

.plustask_more {
  color: #6b6c6f;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.task_data_font_time {
//   color: #34aa44;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  margin-left: 9px;
}
