.syndicates {
  .syndicate_list {
    display: grid;
    gap: 23px;
    grid-template-columns: repeat(3, 1fr);
    // justify-content: center;
    // flex-wrap: wrap;
    // gap: 10px;
    .single_syndicate {
      width: 100%;
      display: flex;
      flex-direction: column;
      // margin-top: 30px;
      margin: 20px;
      gap: 6px;
      // align-items: center;
      justify-content: center;
      cursor: pointer;
      .syndicate_info {
        h2 {
          color: #3e3f42;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
        p {
          color: #9ea0a5;
          font-family: Roboto;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
        }

        .syndicate_bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            color: #939497;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 14.4px */
          }
          h2 {
            color: #3e3f42;
            text-align: center;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
          }
        }
      }
      .view_syndicate_btn {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        display: flex;
        // width: 825px;
        padding: 8px 11px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #d8dce6;
        background: linear-gradient(0deg, #f6f7f9 0%, #fff 100%);
        box-shadow: 0px 1px 1px 0px rgba(22, 29, 37, 0.05), 0px 2px 0px 0px rgba(255, 255, 255, 0.05) inset;
        cursor: pointer;
      }
      .coverImage {
        height: 250px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
.loaderOverlayContainer {
  height: 92vh;
}
