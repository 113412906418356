.headerO {
  height: 75px;

  .menu_icon_sideNav {
    display: none;

    @media screen and (max-width: 1000px) {
      display: block !important;
    }
  }

  .headerO_CON {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    height: 70px;
    background: #fff;
    max-width: 1600px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 0px 0px #eaedf3;
    position: fixed;
    z-index: 999;

    @media screen and (max-width: 950px) {
      padding: 0px 10px !important;
    }

    .headerO-L {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      height: 100%;

      @media screen and (max-width: 1200px) {
        gap: 20px;
      }

      .header-tabs {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        align-items: center;
        height: 100%;

        @media screen and (max-width: 1000px) {
          position: absolute;
          background-color: #fbfbfb;
          right: 0;
          top: 0px;
          flex-direction: column;
          gap: 30px !important;
          display: flex;
          gap: 30px;
          padding-top: 80px;
          height: 100vh !important;
          width: 0px;
          box-shadow: 1px 85px 30px grey;
          transition: width 0.2s ease-in-out;
          overflow: hidden;
          z-index: 1999999999;

          a {
            height: fit-content !important;
            color: #3e3f42;
          }

          .menu_icon_sideNav {
            display: block !important;
          }

          img {
            width: 20px !important;
            margin-right: 10px;
          }
        }

        @media screen and (max-width: 1200px) {
          gap: 5px;

          a {
            font-size: 12px !important;
          }

          .headerO-L {
            gap: 20px !important;
          }
        }

        a {
          display: flex;
          align-items: center;
          padding: 0px 15px;
          text-decoration: none;
          font-family: Roboto;
          color: #3e3f42;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          height: 100%;
          border-bottom: 3px solid transparent;
          transition: all 0.3s ease-in-out;
          white-space: nowrap;

          &:hover {
            border-bottom: 3px solid var(--blue-pantone-2935-c-100, rgba(125, 85, 199, 0.71));
            transform: translateX(5px);
          }

          &.active {
            border-bottom: 3px solid var(--blue-pantone-2935-c-100, rgba(125, 85, 199, 0.71));
            transform: translateX(0);
          }
        }

        a:first-child {
          flex-direction: column;
          justify-content: center;
          row-gap: 5px;
          padding: 0px 10px;

          p {
            font-family: Roboto;
            color: #6b6c6f;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
          }
        }
      }

      .side_nav {
        width: 360px;
        display: flex;
        background-color: #d8dce6;
      }
    }

    .headerO-R {
      display: flex;
      align-items: center;
      gap: 30px;

      p {
        color: #3e3f42;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
      }

      @media screen and (max-width: 1200px) {
        gap: 20px;
        font-size: 12px;

        .req_demo {
          font-size: 12px !important;
          font-size: 12px;
          width: fit-content;
        }
      }

      .PrimaryButton {
        margin: 0px;
        width: 80px;
      }

      .header-info {
        display: flex;
        column-gap: 10px;
        align-items: center;

        .leftComp {
          display: flex;
          align-items: center;
          gap: 5px;
          border-right: 1px solid #eaedf3;
          padding-right: 15px;
          height: 51px;
          > div {
            display: flex;
            padding: 5px 10px;
            flex-direction: column;
            align-items: center;
          }
        }

        .midComp {
          padding: 0 15px;
          border-right: 1px solid #eaedf3;
          height: 51px;
          display: flex;
          align-items: center;
        }

        .rightComp {
          padding-left: 15px;
          height: 51px;
          display: flex;
          align-items: center;

          .profile-details {
            gap: 15px;
          }
        }

        img {
          cursor: pointer;
        }

        .profile-details {
          display: flex;
          column-gap: 10px;
          cursor: pointer;
          align-items: center;

          .profile-pic {
            width: 53px;
            height: 38px;
            object-fit: cover;
            border-radius: 50%;
          }

          h6 {
            font-family: Roboto;
            color: #3e3f42;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;

            span {
              color: #9ea0a5;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.message_icon_wrapper,
.compare_icon_wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
  }

  .message_caption,
  .compare_caption {
    color: var(--LP-mid-grey-_lib, #6b6c6f) !important;
    text-align: center !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 100% !important;
  }
}

.message_icon_wrapper {
  a {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
  }
  .message_icon {
    position: relative;
    width: 20px;
    height: 20px;
    p {
      width: 20px;
      height: 20px;
      position: absolute;
      padding: 6px;
      color: #fff !important;
      background: #1665d8;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 14px;
      left: 8px;
    }
  }
}
.compare_icon_wrapper {
  a {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
  }
}
